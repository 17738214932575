.container {
    display: flex;
    width: 100%;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    /* this style is not in pumpkin-client */
    margin-bottom: 18px;
}

.outlineLabel, .label, .portalLabel {
    color: var(--scoutNavy);
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    top: 0;
    transition: top 0.1s, font-size 0.1s, line-height 0.1s;
    cursor: text;
}

.portalLabel {
    top: 6px;
    left: 14px;
}

.label.placeholder, .outlineLabel.placeholder {
    /* font-size is 16px in pumpkin-client */
    font-size: 14px;
    line-height: 20px;
}

.label.placeholder {
    top: 18px;
}

.outlineLabel.placeholder {
    top: 22px;
    left: 15px;
}

.portalLabel.placeholder {
    top: 15px;
    font-size: 14px;
    line-height: 22px;
    color: var(--gromitGray);
}

.adornmentLabel.placeholder {
    top: 22px;
    left: 34px;
}

.inputContainer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.outlineInputContainer {
    border: 2px solid var(--darkGrey);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.portalInputContainer {
    border: 1px solid var(--pusheenGray);
    background: var(--zeroWhite);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.input {
    border: none;
    border-bottom: 2px solid var(--scoutNavy);
    padding: 6px 0;
    color: var(--scoutNavy);
    font-size: 14px;
    line-height: 20px;
    border-radius: 0;
    background: none;
    width: 100%;
    font-family: Nunito Sans, sans-serif;
}

.input:focus-visible {
    outline: none;
}

.outlineInput {
    padding: 20px 15px;
    color: var(--deepGrey);
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    border: none;
    border-radius: 4px;
    box-sizing: content-box;
    text-align: left;
}

.portalInput {
    padding: 20px 12px 6px;
    color: var(--scoutNavy);
    background-color: var(--zeroWhite);
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    border: none;
    border-radius: 4px;
    box-sizing: content-box;
    text-align: left;
}

.input::placeholder, .outlineInput::placeholder, .portalInput::placeholder {
    visibility: hidden;
}

.portalInput.showPlaceholder {
    padding: 13px 12px;
}

.showPlaceholder.input::placeholder {
    visibility: visible;
}

.startAdornment {
    height: 60px;
    border-radius: 4px 0 0 4px;
}

.startContent {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    min-width: 32px;
    font-size: 14px;
}

.hasStartAdornment {
    padding: 20px 15px 20px 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.iconButton {
    border: 0;
    background: none;
    padding: 0 0 0 4px;
    cursor: pointer;
}

.border {
    border-bottom: 2px solid var(--scoutNavy);
}

.portalInputContainer .border {
    border: none;
}

.portalInputContainer .iconButton {
    padding-right: 12px;
}

.portalInputContainer .spinner {
    padding-right: 10px;
}

.icon-select:not(.filledButton):not(.focused) img {
    opacity: 0.5;
}

.spinner {
    display: flex;
    align-items: center;
}

.focused.label {
    color: var(--bluesClues);
}

.focused.input, .focused.border {
    border-bottom-color: var(--bluesClues);
}

.unfocused .error {
    color: var(--crookshanksRed);
    border-color: var(--crookshanksRed);
}

div.errorMessage {
    color: var(--crookshanksRed);
    font-size: 12px;
    margin-top: 8px;
    min-height: 20px;
    font-weight: 400;
    text-align: left;
}

.asterisk {
    color: var(--crookshanksRed);
}

.readOnly {
    border-color: var(--scoutNavyLight);
    color: var(--scoutNavyLight);
}

.hidden {
    display: none;
}

.endAdornment {
    color: var(--scoutNavyLight);
    font-size: 14px;
    font-family: Nunito Sans, sans-serif;
    display: flex;
}

.endContent {
    padding-top: 10px;
}

/* differences from pumpkin-client */

/* ring styles are here bc pumpkin-client uses a different component for spinners */

.ring {
    width: 24px;
    height: 24px;
}

.ring div {
    border-width: 2px;
}

/* add focus to inputContainers */

.errorBorder.portalInputContainer {
    border: 2px solid var(--crookshanksRed);
}

.focused.portalInputContainer {
    border: 2px solid var(--bluesCluesBlue);
}

.unfocused .error.portalLabel {
    color: var(--gromitGray);
}

/* bc content-/border-box won't work correctly here */
.focused.portalInputContainer .input, .errorBorder.portalInputContainer .input {
    padding: 19px 11px 5px;
}

.focused.portalInputContainer .input.showPlaceholder, .errorBorder.portalInputContainer .input.showPlaceholder {
    padding: 12px 11px;
}

.portalInputContainer.filled:not(.focused):not(.errorBorder) {
    border-color: var(--scoutNavy);
}

