.container {
    width: 100%;
    background: var(--white);
    padding: 26px 32px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.container,
.container>*:not(:last-child) {
    margin-bottom: 16px;
}

.accordionContainer {
    width: 100%;
}

.accordionContent {
    position: initial;
    box-shadow: none;
    padding-top: 5px;
}

.accordionRow {
    border-bottom: none;
}

.claimHeader {
    width: 280px;
    text-overflow: ellipsis;
    color: var(--scoutNavy);
    font-weight: 800;
}

.claimItem,
.claimNotes {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    color: var(--scoutNavyLight);
}

.claimNotes {
    border-top: 1px solid var(--grey);
    padding-top: 16px;
}

.claimProp {
    width: 280px;
    text-overflow: ellipsis;
    font-weight: 600;
}

.claimValue {
    flex: 100%;
    text-align: right;
    font-weight: bold;
}

.claimCompleted {
    color: var(--primaryGreen);
}

.claimInReview {
    color: var(--bluesClues);
}

.downloadButton {
    display: flex;
    align-items: center;
    min-height: 0;
    padding: 0;
    color: var(--bluesClues);
}

.fileIcon {
    height: 18px;
    margin-right: 8px;
}