@value tablet from '~breakpoints';

.root {
    color: var(--white);
    background: var(--bluesClues);
    width: 100%;
    padding: 0 var(--w1);
}

.left {
    display: flex;
    flex-direction: column;
    gap: 4px 10px;
    width: 100%;
    padding: 16px 0;
}

.right {
    display: none;
}

.logo {
    width: 96px;
}

.text {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.underwriting {
    line-height: 16px;
    font-size: 10px;
    color: var(--white);
}

.pawsights {
    font-size: 10px;
    line-height: 16px;
    width: auto;
}

.separator {
    font-size: 10px;
    line-height: 16px;
}

@media tablet {
    .root {
        height: 80px;
        padding: 24px var(--w1);
        position: sticky;
        display: flex;
        justify-content: space-between;
    }

    .left {
        flex-direction: row;
        align-items: flex-end;
        padding: 0;
    }

    .right {
        display: flex;
        align-items: flex-end;
    }

    .logo {
        width: 120px;
    }

    .underwriting {
        line-height: 24px;
        font-size: 16px;
    }

    .pawsights {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    .separator {
        display: none;
    }

}

