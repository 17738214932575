.wrapper {
    width: 100%;
    margin: auto;
    cursor: pointer !important;
}

.label {
    visibility: hidden;
    color: var(--bluesCluesBlue);
}

.visible {
    visibility: visible;
}

div.errorMessage {
    color: var(--crookshanksRed);
    font-size: 12px;
    margin-top: 8px;
    min-height: 20px;
    font-weight: 400;
    text-align: left;
}