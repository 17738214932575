@value b-desktop from '../breakpoints.css';

.subText1 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
  color: var(--scoutNavy);
}

@media b-desktop {
  .subText1 {
    font-size: 14px;
    line-height: 18px;
  }
}