@value b-desktop, b-mobile from '~breakpoints';

.container {
    width: 100%;
    min-height: calc(100vh - 72px);
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newCardButton {
    color: var(--royal);
    display: flex;
    align-items: center;
    margin-top: 30px;
    min-height: 0;
}

.newCardButton img {
    width: 20px;
    margin: -2px 8px 0 0;
}

.loading {
    text-align: center;
}

.ring {
    width: 30px;
    height: 30px;
}

.ring div {
    border-width: 2px;
}

.content {
    width: 100%;
    background: var(--bluesCluesLow);
    padding-bottom: 20px;

    flex: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photoGallery {
    display: grid;
    grid-template-columns: repeat(2, calc(50vw - 40px));
    grid-gap: 24px 4vw;
    padding-top: 32px;
}

.photoGallery img {
    width: 100%;
    min-height: calc((50vw - 40px) * 5/4);
    object-fit: cover;
}

.cardsSent {
    margin-bottom: 8px;
    color: var(--scoutNavyLight);
}

.button {
    margin-top: 32px;
    width: 256px;
}

.noResults {
    width: var(--w12);
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
}

.noResults img {
    margin: 0 auto 32px;
}

.visitButton {
    padding: 0;
    text-align: left;
}

.searchContainer {
    /* on top of nav on mobile */
    z-index: 1000;
}

.photoToolContainer {
    margin-top: 40px;
}

.photoToolHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.photoToolHeader img {
    width: 40px;
    margin-right: 8px;
}

.photoToolSubheader {
    text-align: center;
    margin: 16px 0 40px;
}

@media b-desktop {
    .cardsSent {
        margin-bottom: 12px;
    }

    .photoGallery {
        grid-template-columns: repeat(3, var(--w4));
        grid-gap: 52px calc(var(--w1)/2);
        padding-top: 48px;
    }

    .photoGallery img {
        min-height: calc(var(--w4) * 5/4);
    }

    .noResults {
        width: var(--w5);
    }

    .noSearchResults {
        margin-top: 8px;
    }

    .searchContainer {
        /* hiding behind pop out menu on desktop */
        z-index: 999;
    }

    .searchText {
        min-width: 286px;
    }

    .cardsSent {
        font-size: 14px;
    }

    .photoToolModal {
        width: var(--w10);
    }

    .photoToolContainer {
        margin: 20px 32px 32px;
    }

    .photoToolHeader {
        margin-top: 60px;
    }
}

@media b-mobile {

    /* on top of nav on mobile but behind popout menu */
    .searchContainer.nav {
        z-index: 900;
    }
}