@value b-desktop from '~breakpoints';

.patientSortContainer {
    display: flex;
    justify-content: flex-end;
    width: var(--column10);
    min-height: 18px;
}

.headerButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 0px;
}

.headerLabel,
.selectedOption {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.selectedOption {
    color: var(--royal);
    padding: 0 10px 0 4px;
    min-height: 0px;
}

.icon {
    width: 12px;
}

.reverse {
    transform: rotate(180deg);
}

.popover {
    border-radius: 8px;
    padding: 20px 0;
    margin-top: -38px;
    box-shadow: 0 12px 26px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 14px;
}

.optionButton {
    min-height: 0;
    width: 100%;
    display: block;
    text-align: left;
}

.optionlabel {
    font-size: 14px;
    line-height: 20px;
}

@media b-desktop {
    .patientSortContainer {
        width: var(--column6);
    }
}