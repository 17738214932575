@value tablet from '~breakpoints';

.root {
    background: var(--peteyBlue);
    padding: 32px var(--w1) 54px;
    text-align: center;
}

.root img {
    width: var(--w14);
    max-width: 360px;
    margin: 0 auto;
}

.root img:first-child {
    width: 187px;
    margin-bottom: 8px;
}

.root h1 {
    font-size: 36px;
    line-height: 48px;
    color: var(--scoutNavy);
    margin-bottom: 24px;
}

.root h5 {
    font-size: 24px;
    line-height: 32px;
}

.text {
    max-width: 730px;
    margin: 0 auto 40px;
}

.root h5 {
    max-width: 730px;
    margin: 0 auto 16px;
}

.root button {
    margin-bottom: 40px;
}

.root a {
    color: var(--dinoBlue);
    text-decoration: none;
}

.root a:hover {
    text-decoration: underline;
}

@media tablet {
    .root {
        padding: 32px var(--w2);
    }

    .root img {
        width: 360px;
        max-width: unset;
    }

    .root h1 {
        font-size: 40px;
    }
}