@value b-desktop from '~breakpoints';

.title {
    text-align: center;
    margin-top: 48px;
}

.subtitle {
    margin-top: 20px;
    text-align: center;
}

.preview {
    position: relative;
    margin-top: 40px;
    padding: 32px;
    background: var(--white);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.submit {
    width: 250px;
    margin: 40px auto 20px;
    display: block;
}

.error {
    color: var(--redAlert);
    text-align: center;
}

.icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

@media b-desktop {
    .container {
        width: 350px;
        margin: auto;
    }

    .title {
        margin: 24px;
    }
}