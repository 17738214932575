.filterContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.filterButton {
    display: flex;
    font-weight: 700;
    color: var(--royal);
    min-height: 0px;
}

.icon {
    width: 18px;
    margin-right: 7px;
}

.popover {
    top: 100%;
    margin-top: -8px;
    box-shadow: 0 12px 26px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 16px;
}