.root {
    padding: 80px 56px 0;
    color: var(--cujoCream);
}

.title {
    color: var(--zeroWhite);
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 24px;
}

.root ul {
    padding-left: 24px;
}

.root p, .root li {
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
}

.root p, .root ul {
    margin-bottom: 18px;
}