@value desktop from 'breakpoints.css';

:root {
    --heading-font: Poppins, sans-serif;
    --main-font: Nunito Sans, sans-serif;
}

.reset {
    margin: 0;
    padding: 0;
}

.body1, .body2 {
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.bodySubhead {
    font-family: var(--main-font);
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
}

:global(h1) {
    color: var(--bluesClues);
}

:global(h2), :global(h3), :global(h4), :global(h5), :global(h6), .body1, .bodySubhead, .legalBody, .legalBodySubhead {
    color: var(--scoutNavy);
}

.body2, .subText1 {
    color: var(--scoutNavyLight);
}

.legalBody {
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.legalBodySubhead {
    font-family: var(--main-font);
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
}

.subText1 {
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.h1, .h2, .h3, .h4, .h5, .h6, .body1, .body2, .bodySubhead, .legalBody, .legalBodySubhead, .subText1 {
    composes: reset;
}

/* Fonts */
.h1, :global(h1) {
  font-family: var(--heading-font);
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
}

.h2, :global(h2) {
  font-family: var(--heading-font);
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
}

.h3, :global(h3) {
  font-family: var(--heading-font);
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}

.h4, :global(h4) {
  font-family: var(--heading-font);
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}

.h5, :global(h5) {
  font-family: var(--main-font);
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

.h6, :global(h6) {
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.inject {
    display: none;
}

@media desktop {
    .h1, :global(h1) {
        font-size: 40px;
        line-height: 48px;
    }

    .body1, .body2, .bodySubhead {
        font-size: 16px;
        line-height: 24px;
    }

    .subText1 {
        font-size: 14px;
        line-height: 18px;
    }
}