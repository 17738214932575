@value b-desktop from '~breakpoints';

.nav,
.basic,
.basicPawPortal {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    background: var(--puppyPurple);
    display: flex;
    align-items: center;
}

.basic {
    height: 60px;
}

.basicPawPortal {
    height: 72px;
}

.basic, .basicPawPortal {
    justify-content: center;
}

.basic *, .basicPawPortal * {
    color: var(--white);
}

.basic img {
    width: 108px;
}

.nav {
    height: 72px;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
}

.menuButton {
    padding-left: 0;
}

.toggleImage {
    width: 16px;
}

.home {
    height: 28px;
    text-decoration: none;
}

.logo {
    color: var(--white);
    letter-spacing: 0;
    display: flex;
    align-items: flex-start;
}

.trademark {
    line-height: 1;
    font-size: 10px;
    margin-top: 4px;
    margin-right: 10px;
}

.popover {
    top: 72px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
}

.fileClaim {
    min-height: 40px;
    width: 130px;
    font-size: 14px;
    line-height: 16px;
}

.menuItem,
.account {
    padding: 20px 32px;
    border-bottom: 1px solid var(--grey);
    display: flex;
    align-items: center;
    text-decoration: none;
}

.account {
    background: var(--bluesCluesLow);
    padding-left: 24px;
}

.accountLogo *,
.accountName *,
.signOut {
    font-size: 14px;
    line-height: 20px;
}

.accountLogo {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.accountName {
    display: flex;
    flex-direction: column;
}

.signOut {
    flex: 1;
    text-align: right;
}

.accountName a,
.signOut {
    color: var(--royal);
    text-decoration: none;
}

.disabledText div {
    color: var(--scoutNavyHalf);
}

.linkIcon {
    width: 30px;
    margin-right: 18px;
}

.contact {
    width: 235px;
    margin: 30px 32px;
}

@media b-desktop {
    .popover {
        width: auto;
    }
}