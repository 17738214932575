@value tablet from '~breakpoints';

.root {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.title {
    color: var(--scoutNavy);
    font-size: 18px;
    line-height: 28px;
    margin: 0 auto;
    flex: 100%;
}

.subtitle {
    margin: -10px auto 0;
    font-size: 14px;
    line-height: 22px;
    color: var(--scoutNavy);
    flex: 100%;
}

.fullWidth {
    flex: 100%;
    border-radius: 4px;
}

.halfWidth {
    flex: 1;
}

.marginZero {
    margin-bottom: 0;
}

.toggle {
    flex: 1 1 40%;
    border-radius: 4px;
    justify-content: center;
    box-sizing: border-box;
    padding: 0;
    height: 48px;
}

.toggleImg {
    width: 26px;
}

.errorBorder {
    border: 2px solid var(--rowlfRed);
}

.error {
    color: var(--rowlfRed);
    flex: 100%;
    font-size: 12px;
    margin-top: -8px;
    text-align: left;
}

.select {
    flex: 100%;
    height: 46px;
    padding: 0 12px;
    border: 1px solid var(--pusheenGray);
    border-radius: 4px;
    font-size: 14px;
    color: var(--gromitGray);
}

.filled {
    border-color: var(--scoutNavy);
    color: var(--scoutNavy);
}

.create, .wait {
    width: 100%;
    text-align: center;
}

.create {
    color: var(--bluesCluesBlue);
    font-size: 24px;
}

.wait {
    font-size: 16px;
    font-weight: 800;
    margin-top: -12px;
}

.img {
    margin: 8px auto 160px;
    width: 200px;
}

@media tablet {
    .create {
        font-size: 32px;
    }

    .wait {
        font-size: 18px;
    }

    .img {
        margin-bottom: 28px;
    }
}