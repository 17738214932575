@value b-desktop from '~breakpoints';

.header {
    padding: 64px var(--w1) 24px;
    text-align: center;
    border-bottom: 1px solid var(--grey);
}

.header h3 {
    margin-bottom: 24px;
}

.subHeader {
    max-width: 579px;
    margin: 0 auto;
    font-size: 16px;
}

.pep3Wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 32px 0 -4px;
    padding: 32px 24px;
    background-color: var(--cream);
}

.pep3Text {
    width: 100%;
    max-width: 496px;
    text-align: left;
}

.pep3Header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.pep3SubHeader {
    font-size: 14px;
    line-height: 20px;
    opacity: 1;
}

.pep3Button {
    width: 219px;
    margin-top: 16px;
}

.pep3Image {
    display: none;
    height: 202px;
}

.sendMail {
    padding: 26px var(--w1);
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.sendMail div {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.sendMail button {
    min-width: 224px;
    margin: 24px 0 8px;
}

.cardsSection {
    display: flex;
    flex-direction: column;
    background-color: var(--bluesCluesLow);
}

.cardsContainer {
    width: 100%;
    padding: 0 var(--w1) 64px;
}

.cardsContainer > div:not(:last-child) {
    max-width: 1260px;
    /* 14 columns on 1440px width */
    margin: 0 auto;
}

.processing {
    display: flex;
    justify-content: center;
    padding: 64px 0;
}

.targetTitle {
    display: flex;
    align-items: center;
    margin-top: 64px;
}

.targetTitle img {
    width: 48px;
    margin-right: 18px;
}

.seeMore {
    margin-top: 40px;
    text-align: center;
}

@media b-desktop {
    .header {
        padding-bottom: 44px;
    }

    .pep3Wrapper {
        padding: 40px 40px 0 40px;
    }

    .pep3Header {
        margin-bottom: 16px;
    }

    .pep3Button {
        width: 181px;
        margin-top: 24px;
        margin-bottom: 34px;
    }

    .pep3Image {
        display: block;
    }

    .sendMail {
        flex-direction: row;
    }

    .sendMail div {
        text-align: left;
        margin-right: 52px;
    }

    .sendMail button {
        margin: 0;
    }
}