@value b-mobile from '~breakpoints';

.link,
.content {
    max-width: 340px;
    margin: 0 auto;
    text-align: center;
}

.link {
    display: block;
    margin-top: 20px;
    color: var(--royal);
    text-decoration: none;
}

@media b-mobile {
    .link {
        font-size: 14px;
    }
}