.root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -37%);

    height: 181px;
}

.root h3 {
    margin: 114px auto 8px;
}

.number {
    font-weight: 800;
    font-size: 80px;
    line-height: 102px;
    letter-spacing: -1px;

    display: flex;
    align-items: center;
    flex: 1;

    color: var(--bluesCluesBlue);
    margin: 0 auto;
}

.number.inactive {
    margin: 0 auto;
    font-size: 140px;
    display: flex;
    align-items: center;
}

.block {
    width: 100%;
    border-radius: 8px;
    padding: 16px 16px 24px;
    background-color: var(--peteyBlue);
}

.block p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 328px;
    margin: 0 auto;
}

.block p:first-child {
    margin: 0 auto 8px;
    width: 260px;
}