@value b-desktop from '~breakpoints';

.utility,
.create {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
}

.utility {
    height: 37px;
    background: var(--cream);
    justify-content: end;
}

.subhead {
    margin-left: 16px;
    color: var(--bluesClues);
}

.create {
    height: 72px;
    background: var(--bluesClues);
    justify-content: space-between;
}

.create button {
    width: 130px;
}

@media b-desktop {
    .subhead {
        margin-left: 30px;
    }

    .create button {
        width: 201px;
    }
}