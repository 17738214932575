@value tablet from '~breakpoints';

.underwriting {
    font-size: 16px;
    color: var(--white);
}

.content {
    padding: 32px var(--w1) 64px;
    max-width: 1440px;
    margin: 0 auto;
}

.content form {
    width: var(--w14);
    margin: 0 auto;
}

.stepperRoot {
    padding-left: var(--w1);
    padding-right: var(--w1);
}

.stepperImg {
    width: 48px;
    height: 48px;
}

.desktop {
    display: none;
}

.content h3 {
    color: var(--bluesCluesBlue);
    font-size: 24px;
    line-height: 32px;
    margin: 42px auto 46px;
    font-weight: 700;
    text-align: center;
    width: 272px;
    height: 56px;
}

.dropdownMessage {
    margin: 24px var(--w1) 12px;
    padding: 18px 16px 12px;
    border-bottom: none;
    background-color: var(--peteyBlue);
    border-radius: 8px;

    font-size: 14px;
    line-height: 22px;
}

.question {
    font-weight: 800;
    color: var(--scoutNavy);
    font-size: 18px;
    line-height: 28px;
}

.question sup {
    color: var(--cliffordRed);
    margin-left: 4px;
}

.radioGroup {
    padding: 6px 0 12px;
}

.textContainer {
    padding: 6px 0 12px;
}

.select {
    padding: 6px 0 30px;
}

.radio {
    flex: 1 1 40%;
    height: 144px;
    padding: 15px 0;
}

.radioChecked {
    padding: 14px 0 !important;
}

.radioLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px 0;
}

.radioRadio {
    top: 15px !important;
}

.radioImage {
    top: 14px !important;
}

.radioLabel img {
    height: 72px;
    padding-top: 12px;
}

.legalPermission, .legalPrivacy {
    font-style: italic;
    color: var(--scoutNavy);
    font-size: 14px;
    line-height: 22px;
}

.legalPrivacy {
    margin-top: 12px;
}

.legalPermission {
    margin-bottom: 18px;
}

.legalPermission a, .legalPrivacy a {
    color: var(--bluesCluesBlue);
    text-decoration: none;
}

.legalPermission a:hover, .legalPrivacy a:hover {
    text-decoration: underline;
}

.button {
    margin: 2px auto 0;
    width: 100%;
    display: block;
    border-radius: 4px;
}

.bio {
    padding: 18px 16px;
    background-color: var(--cujoCream);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 32px;
    border-radius: 8px;
    margin-top: 6px;
    margin-bottom: 12px;
}

.bioHelp {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
}

.bioHalf, .bioFull {
    flex: 100%;
}

@media tablet {
    .content {
        padding: 32px var(--w4) 80px;
    }

    .content form {
        width: var(--w6);
    }

    .stepperRoot {
        padding-left: 0;
        padding-right: 0;
    }

    .stepperImg {
        width: 56px;
        height: 56px;
    }

    .desktop {
        display: inline;
    }

    .content h3 {
        font-size: 30px;
        line-height: 36px;
        margin: 36px auto 30px;
        width: 100%;
        height: 45px;
    }

    .dropdownMessage {
        display: none;
    }

    .legalPermission {
        margin-bottom: 44px;
    }

    .radioGroup {
        padding-bottom: 18px;
    }

    .button {
        margin-top: 18px;
        width: 256px;
    }

    .bio {
        margin-bottom: 18px;
    }

    .bioHalf {
        flex: 40%;
    }
}