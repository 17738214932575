@value b-desktop from '~breakpoints';

.content {
    padding: 16px var(--column1);
}

.title {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check {
    margin-right: 10px;
}

.card {
    display: flex;
    align-items: flex-start;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 24px 40px 24px 24px;
    margin: 15px 0 30px;
    position: relative;
    background-color: var(--white);
}

.icon {
    position: absolute;
    width: 18px;
    top: 12px;
    right: 12px;
    opacity: 0.25;
}

.banner {
    width: 88px;
    margin-right: 16px;
    border-radius: 10px;
}

.field {
    margin-bottom: 12px;
}

.message {
    max-width: 370px;
    text-align: center;
    margin: 0 auto 36px;
}

.button {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
}

.error {
    color: var(--redAlert);
    text-align: center;
    margin-top: 16px;
}

.accordionContainer {
    width: 100%;
}

.accordionContent {
    box-shadow: none;
    position: relative;
}

.accordionContent>* {
    border-bottom: none;
}

.modal {
    margin: auto auto;
    width: var(--column10);
    max-width: 313px;
    height: inherit;
    text-align: center;
    border-radius: 8px;
    min-width: 0;
    min-height: 0;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
}

.modalButton {
    width: 47%;
    max-width: 115px;
}

@media b-desktop {
    .content {
        padding: 36px;
    }

    .card {
        padding: 24px;
    }

    .banner {
        width: 130px;
        margin-right: 24px;
    }
}