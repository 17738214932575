@value tablet from '~breakpoints';

.root {
    padding: 0 var(--w1);
    text-align: center;
    color: var(--white);
    background: var(--bluesClues);
}

.root h1 {
    color: var(--white);
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 12px;
}

.root.hasPrices h1 {
    margin-bottom: 16px;
}

.petInfo {
    margin: 0 auto 24px;
    max-width: 830px;
    font-size: 16px;
    line-height: 24px;
}

.hasPrices .petInfo {
    margin-bottom: 16px;
}

.planInfo {
    font-weight: bold;
}

.prices {
    font-style: italic;
    margin-bottom: 16px;
}

.petIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 9px;
}

.customize, .quote {
    border-radius: 4px;
    min-width: 256px;
    margin: 0 0 24px;
}

.customize:hover, .quote:hover {
    background-color: var(--plutoGold);
    color: var(--scoutNavy);
    border: 1px solid var(--plutoGold);
}

@media tablet {
    .customize, .quote {
        margin-bottom: 64px;
    }

    .root h1 {
        font-size: 42px;
        line-height: 52px;
    }
}