.container {
    width: 100%;
    position: relative;
}

.container * {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently */
}

.wrapper {
    z-index: 1;
    float: left;
    position: absolute;
    right: 0;
    top: 60px;
    width: 300px;
    background: var(--white);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    padding: 15px;
}

.head {
    float: left;
    width: 100%;
    height: 53px;
}

.calendarBody {
    float: left;
    width: 100%;
}

.inputContainer {
    display: flex;
    position: relative;
}

.mobileHandler {
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: 1;
}

.input {
    position: absolute;
    z-index: -1;
}

.inputIcon {
    width: 100%;
}

.buttonIcon {
    position: absolute;
    right: 0px;
    top: 12px;

    cursor: pointer;
    height: 28px;
    width: 28px;
    padding: 0;
    border: none;
    background: none;
}

.button {
    float: left;
    width: 42px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.inner:hover {
    cursor: pointer;
    background: var(--grey);
}

.inner {
    float: left;
    height: 35px;
    width: 35px;
    background: var(--greyLight);
    border-radius: 100%;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -17px;
    border: none;
}

.rightArrows:after,
.leftArrows:after,
.rightArrow,
.rightArrows,
.leftArrow,
.leftArrows {
    display: block;
    float: left;
    width: 6px;
    height: 6px;
    border-left: 2px solid var(--scoutNavy);
    border-bottom: 2px solid var(--scoutNavy);
    position: absolute;
}

.rightArrow,
.rightArrows,
.leftArrow,
.leftArrows {
    transform: rotate(45deg);
    left: 50%;
    top: 50%;
    margin-left: -2px;
    margin-top: -4px;
}

.rightArrows,
.rightArrow {
    transform: rotate(225deg);
    margin-left: -4px;
}

.rightArrows:after,
.leftArrows:after {
    content: '';
}

.leftArrows {
    margin-left: -5px;
}

.rightArrows {
    margin-left: -2px;
}

.rightArrows:after,
.leftArrows:after {
    left: 3px;
    top: -5px
}

.headerContainer {
    float: left;
    width: 100px;
    height: 100%;
}

.year {
    float: left;
    width: 100%;
    height: 30px;
    font-size: 27px;
    color: var(--scoutNavyLight);
    font-weight: 200px;
    text-align: center;
}

.month {
    float: left;
    width: 100%;
    height: 15px;
    font-size: 13px;
    color: var(--scoutNavyLight);
    font-weight: 200px;
    text-align: center;
}

.calendarHead,
.calendarDayName,
.calendarBody,
.day button,
.day,
.dayContainer,
.calendarContainer {
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.calendarContainer {
    width: 100%;
    height: 100%;
}

.calendarHead {
    height: 30px;
    width: 100%;
}

.calendarDayName {
    width: 14.285%;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    color: var(--scoutNavyLight);
    font-size: 9px;
    text-align: center;
}

.calendarBody {
    height: 200px;
    width: 100%;
}

.dayContainer {
    width: 14.285%;
    height: 16.6666%;
}

.day {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: var(--scoutNavyLight);
    text-align: center;
}

.day button {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: var(--scoutNavy);
    border: none;
    background: none;
}

.day button:hover {
    cursor: pointer;
    background: var(--greyLight);
}

.day button {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    left: 50%;
    top: 50%;
    font-weight: 400;
    border-radius: 100%;
}

.dayContainer.disabled {
    pointer-events: none;
}

.dayContainer.disabled .day button {
    color: var(--grey);
    background: var(--white);
}

.dayContainer.highlight .day button {
    background: var(--lightYellow);
}

.dayContainer.highlightActive .day button {
    background: var(--bluesClues);
    color: var(--white);
}