@value b-desktop, b-desktop-only from '~breakpoints';

.container {
    width: 100%;
    background: var(--white);
    padding: 24px 32px 44px 32px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: 24px;
    max-height: 264px;
    overflow-y: hidden;
    position: relative;

    transition: 0.4s;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ages {
    order: 1;
    display: flex;
    align-items: center;
}

.ages img {
    height: 24px;
    width: 24px;
    margin-right: 12px;
}

.entitlements {
    order: 3;
    flex: 1;
    width: 100%;
    padding-top: 24px;
    border-top: 1px dashed var(--scoutNavyLight);
}

.entitlements>div:not(:first-child)>.category {
    margin-top: 20px;
}

.entitlements .category {
    font-size: 14px;
}

.entitlements ul {
    list-style: none;
}

.entitlements li {
    display: flex;
    align-items: flex-start;
    margin: 8px 0;
    font-size: 14px;
    color: var(--scoutNavy);
}

.entitlements li img {
    margin: -2px 10px 0 0;
}

.cost {
    order: 2;
    margin: 18px 0 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.cost h4 {
    margin-right: 6px;
}

.addon {
    line-height: 21px;
    font-size: 14px;
    color: var(--scoutNavyLight);
}

.savings {
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
    color: var(--scoutNavy);
    text-align: left;
    flex: 100%;
}

.savings span {
    color: var(--redAlert);
}

.savings a {
    color: var(--royal);
}

.control {
    cursor: pointer;
    border: none;

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 20px 20px var(--white);
    background-color: white;
    font-size: 14px;
    line-height: 20px;
    color: var(--royal);
    padding-bottom: 16px;

    transition: 0.4s;
}

.control img {
    margin-left: 8px;
    width: max-content;
}

.h4 {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bolder;
}

@media b-desktop {
    .container {
        max-height: 240px;
        padding-bottom: 52px;
    }

    .content {
        flex-direction: row;
    }

    .ages {
        flex: 1;
        max-width: 296px;
        margin-right: 32px;
    }

    .entitlements {
        order: 2;
        padding-top: 0;
        border-top: none;
    }

    .cost {
        order: 3;
        width: 128px;
        margin: 0 0 0 32px;
        text-align: right;
        border-bottom: none;
        flex-direction: column;
        align-items: flex-start;
    }

    .cost h4 {
        margin-right: 0;
    }

    .addon {
        margin-top: 12px;
        line-height: 20px;
    }

    .h4 {
        font-size: 18px;
    }
}

@media b-desktop-only {
    .ages {
        width: 296px;
    }
}

.expandedContainer {
    max-height: 999px;
}

.expandedControl {
    box-shadow: none;
}

.expandedControl img {
    transform: rotate(180deg);
}