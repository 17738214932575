.root h3 {
    margin: 114px auto 28px;
}

.img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -37%);
    width: 236px;
}

.qr {
    width: 160px;
    margin: 0 auto 24px;
}

.block {
    width: calc(100% - 32px);
    margin: 8px auto 0;
    padding: 8px;
    background-color: var(--peteyBlue);
}

.block p {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    padding: 8px;
    width: 100%;
    border: 3px dashed var(--huckleberryBlue);
}