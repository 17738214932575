@value b-desktop from '~breakpoints';

.root {
    background: var(--puppyPurple);
}

.container {
    width: 100%;
    position: relative;
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
}

.text {
    color: var(--white);
    text-align: center;
    padding: 30px 0 180px;
    width: 300px;
    margin: 0 auto;
}

.images {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 46%);

    display: flex;
    align-items: self-end;
    justify-content: center;
}

.nena {
    width: 260px;
    transform: rotate(-4deg);
    margin-left: -25px;
}

.peanut {
    width: 167px;
    transform: rotate(-10deg);
    margin-left: -55px;
    margin-top: 10px;
}

.row {}

@media b-desktop {
    .text {
        width: 515px;
    }

    .row {
        padding: 55px 0;
    }

    .row .text {
        margin-left: var(--column1);
        width: var(--column6);
        max-width: 515px;
        padding: 0;
        text-align: left;
    }

    .row .images {
        padding-right: var(--column1);
        justify-content: flex-end;
    }
}