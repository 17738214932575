.container {
    display: inline-block;
    position: relative;
    text-align: center;
}

.left {
    text-align: left;
}

.tooltip {
    position: absolute;
    margin: 20px 30px;
    left: calc(50% - 30px);
    /* 30px is an arbitrary value to give the tooltip more invisible area for mobile users*/
    top: 100%;
    transform: translateX(-50%);
    padding: 10px;

    background: var(--bluesClues);
    color: var(--white);
    border-radius: 5px;

    font-size: 12px;
    line-height: 16px;
    min-width: 145px;
}

.tooltip:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent var(--bluesClues) transparent;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.wide {
    min-width: 200px;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
}

.newFeature {
    text-align: inherit;
    color: inherit;
    padding: 0 0 6px;
    font-size: 14px;
    line-height: 20px;
}

.close {
    position: absolute;
    padding: 0;
    min-height: 0;
    top: 18px;
    right: 18px;
}

.close img {
    width: 16px;
    height: 16px;
    filter: brightness(0) invert(1);
}