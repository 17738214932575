.container {
    display: flex;
    position: relative;
}

.input {
    opacity: 0;
}

.box {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border: 3px solid var(--scoutNavy);
    border-radius: 2px;
    background: none;
}

.input:checked~.box {
    background-color: var(--royal);
    border-color: var(--royal);
    background-image: url('check.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    margin-left: 20px;
    color: var(--scoutNavy);
}

.asterisk {
    color: var(--redAlert);
}

.error {
    color: var(--redAlert);
    border-color: var(--redAlert);
}