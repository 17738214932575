.legalSection {
    background-color: var(--cream);
    padding: 108px var(--column1) 64px;
}

.legal {
    color: var(--scoutNavyLight);
    max-width: 1440px;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto 24px;
}

.legal a {
    color: var(--royal);
    text-decoration: none;
}

.legal a:hover {
    text-decoration: underline;
}