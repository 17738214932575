@value b-desktop from '~breakpoints';

.content {
    padding: 72px var(--column1) 48px;
}

.title {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check {
    margin-right: 10px;
}

.copy {
    text-align: center;
    margin-bottom: 36px;
}

.field {
    margin-bottom: 4px;
}

.button {
    display: block;
    width: 100%;
    margin: 0 auto;
}

.error {
    color: var(--redAlert);
    text-align: center;
    margin-top: 16px;
}

@media b-desktop {
    .container {
        min-width: auto;
        min-height: auto
    }

    .content {
        width: var(--column6);
    }
}