@value b-desktop, b-mobile, tablet from '~breakpoints';

.heart {
    position: absolute;
    right: 35px;
    transform: translateY(-50%);
    top: 0;
}

.container {
    padding: 50px var(--column1) 90px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
}

.container>* {
    flex: 100%;
}

.blueContainer {
    background-color: var(--bluesCluesLow);
}

.imageHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.headerCamera {
    margin-right: 16px;
    width: 30px;
}

.module {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 30px 32px;
    margin-top: 30px;
    background-color: var(--white);
}

.moduleIcon {
    width: 29px;
    margin-right: 14px;
}

.link {
    margin-top: 18px;
    display: block;
}

.button {
    width: 100%;
}

.disabledText {
    color: var(--scoutNavyHalf);
}

.desktopOnly {
    display: none;
}

@media b-mobile {
    .hiddenPopover {
        display: none;
    }
}

@media b-desktop {
    .heart {
        right: var(--column1);
    }

    .module {
        flex: 48.5%;
    }

    .module:nth-child(odd) {
        margin-right: 1.5%;
    }

    .module:nth-child(even) {
        margin-left: 1.5%;
    }

    .button {
        width: 156px;
    }
}

@media tablet {
    .desktopOnly {
        display: block;
    }
}