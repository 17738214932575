@value b-desktop from '../breakpoints.css';

.body2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--scoutNavy);
}

@media b-desktop {
  .body2 {
    font-size: 16px;
    line-height: 24px;
  }
}