.root {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.root,
.root * {
    cursor: pointer;
}

.label {
    font-size: 14px;
    line-height: 18px;
    margin-inline-start: 10px;
}

.radio {
    position: absolute;
    z-index: 1;
    margin: 0;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
}

.radio:focus-visible {
    outline-color: var(--bluesCluesLight);
}

.image {
    z-index: 2;
    background-size: contain;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    flex: 0 0 34px;
}

.smallRadio+.image {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
}

.smallRadio {
    width: 22px;
    height: 22px;
}

.medium {
    font-size: 18px;
    line-height: 24px;
}

.small {
    font-size: 14px;
    line-height: 20px;
}

.radio:not(:checked)+.image {
    background-image: url('radio.png');
}

.radio:checked+.image {
    background-image: url('radio-checked.png');
}

.portal {
    border: 1px solid var(--pusheenGray);
    padding: 12px 0;
    border-radius: 4px;
    position: relative;
}

.portal .image {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
}

.portal .radio {
    transform: none;
    width: 22px;
    height: 22px;
    top: 13px;
    left: 13px;
}

.portal .label {
    margin-left: 48px;
}

.portal .label.center {
    margin: 0 auto;
}

.portal.checked {
    background-color: var(--peteyBlue);
    border: 2px solid var(--bluesCluesBlue);
    padding: 11px 0;
    font-weight: 800;
}

.portal.error {
    border: 2px solid var(--crookshanksRed);
    padding: 11px 0;
}