@value b-desktop from '~breakpoints';

.petButton {
    background: var(--white);
    padding: 12px;

    width: 100%;
    display: flex;
    align-items: center;
}

.petImage {
    width: 50px;
    height: 50px;
    margin-right: 12px;
}

@media b-desktop {
    .petButton {
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        margin-top: 16px;
    }

    .selected {
        background: var(--puppyPurple);
    }

    .selected.petName {
        color: var(--white);
    }

    .selected img {
        box-shadow: 0 0 0 2px var(--white);
        border-radius: 50%;
    }
}