@value b-desktop from '../breakpoints.css';

.container {
    position: relative;
}

.header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.staticContent {
    width: 100%;
    text-align: left;
}

.button {
    display: flex;
    align-items: center;
    color: var(--royal);
    padding: 0;
    min-height: 0;
    white-space: nowrap;
}

.chevron {
    transition: 0.2s;
    margin-left: 8px;
}

.chevron.openChevron {
    transform: rotate(180deg);
}

.expandedContainer {
    position: absolute;
    left: 0;
    top: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 1;
}

.row {
    border-bottom: 1px solid var(--grey);
}

@media b-desktop {}