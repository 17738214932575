@value b-desktop from '../breakpoints.css';

.backdrop {
    z-index: 1;
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header {
    position: absolute;
    width: 100%;
    max-width: 1440px;
    height: 93px;
    background-color: var(--cream);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    z-index: 2;
}


.loading {
    position: absolute;
    top: 93px;
    width: 100%;
    height: calc(100vh - 165px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--greyLight);
    z-index: 1;
}

.spinner {
    width: 60px;
    height: 60px;
}


.name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
}

.name img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.exit {
    display: flex;
    align-items: center;
    color: var(--royal);
}

.exit img {
    width: 12px;
    height: 12px;
    margin-right: 0;
}

.exit span {
    display: none;
}

.content {
    overflow: hidden;
    width: 100%;
}

.iframe {
    position: relative;
    width: calc(100% + 64px);
    height: calc(100vh - 72px);
    left: -32px;
    border: none;
}

@media b-desktop {
    .header {
        height: 115px;
        padding: 0 80px;
    }

    .loading {
        top: 115px;
        height: calc(100vh - 187px);
    }

    .exit img {
        margin-right: 12px;
    }

    .exit span {
        display: block;
    }
}