.title {
    text-align: center;
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check {
    margin-right: 10px;
    width: 30px;
}

.subtitle {
    margin: 20px auto 40px;
    text-align: center;
    width: 313px;
}

.photo {
    width: 250px;
    margin: 0 auto 20px;
}

.mobileAction,
.desktopText {
    font-weight: 700;
}

.mobileAction {
    text-align: center;
}

.desktopAction {
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktopText {
    color: var(--royal);
}

.desktopAction:hover {
    text-decoration: none;
}

.download {
    width: 24px;
    margin-right: 8px;
}

.hidden {
    display: none;
}

.button {
    width: 250px;
    margin: 40px auto;
    display: block;
}