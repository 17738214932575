@value tablet, desktop from '~breakpoints';

.content, .contact {
    width: 100%;
    padding: 32px var(--w1);
}

.content, .loading {
    background: var(--peteyBlue);
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.minHeight {
    min-height: 655px;
}

.content h1 {
    color: var(--scoutNavy);
    text-align: center;
    margin-bottom: 16px;
}

.blocks, .blocksDetails {
    display: flex;
    flex-direction: column;
    gap: 24px 16px;
}

.uploadBlock, .petBlock, .docBlock {
    background: var(--zeroWhite);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    flex: 100%;
}

.uploadBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    padding: 20px;
}

.petBlock, .docBlock {
    padding: 32px 24px;
}

.uploadBlock > p {
    font-size: 16px;
    line-height: 24px;
}

.uploadBlock span {
    cursor: pointer;
    color: var(--dinoBlue);
}

.uploader {
    margin-top: 0;
    border-color: var(--scoutNavy);
    border-width: 1px;
}

.blocksDetails {
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    flex-direction: row;
}

.item:not(:first-child) {
    margin-top: 24px;
}

.imgSpacing {
    width: 44px;
    flex: 44px 0 0;
    margin-right: 12px;
}

.petBlock img {
    width: 40px;
}

.blockText {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blockText ul {
    padding-left: 20px;
    margin-bottom: 8px;
}

.contact {
    text-align: center;
}

.contact h3 {
    max-width: 300px;
    font-size: 30px;
    line-height: 36px;
    margin: 0 auto 16px;
}

.contact h4 {
    max-width: 340px;
    font-size: 24px;
    line-height: 28px;
    margin: 0 auto 16px;
}

.contactItems {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.contactItem {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.contactDetails a, .contactDetails p {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

.contactDetails a {
    color: var(--royal);
    font-weight: 800;
    text-decoration: none;
}

.contactDetails a:hover {
    text-decoration: underline;
}

.peteyBlue {
    background: var(--peteyBlue);
}

.white {
    background: var(--zeroWhite);
}

@media tablet {
    .minHeight {
        /* 72px header, 2/3 of footer */
        min-height: calc(100vh - 72px - 200px);
    }

    .content {
        padding-bottom: 56px;
    }

    .content h1 {
        margin-bottom: 32px;
    }

    .blocks {
        flex-direction: row;
        align-items: flex-start;
    }

    .uploadBlock, .blocksDetails {
        flex: 50%;
    }

    .uploadBlock > button {
        width: 168px;
        margin: 0 auto;
    }

    .contact h3, .contact h4 {
        max-width: unset;
    }

    .contactItems {
        flex-direction: row;
        justify-content: center;
        gap: 48px;
    }
}

@media desktop {
    .minHeight {
        /* 72px header, 2/3 of footer */
        min-height: calc(100vh - 72px - 160px);
    }

    .blocksDetails {
        width: var(--w6);
    }
}