@value tablet, desktop from '../breakpoints.css';

.modal {
    z-index: 2147483001;
    /* z-index for gladly */
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px 0 0;
}

.modalDialog {
    position: relative;
    display: table;
}

.backdrop {
    position: fixed;
    background-color: rgba(var(--scoutNavyRgb), 0.7);
    z-index: 2147483000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.container {
    position: relative;
    display: table;
    clear: both;
    flex-direction: column;
    background-color: var(--zeroWhite);
    background-clip: padding-box;
    overflow: hidden;
    outline: 0;
    border-radius: 0;
    padding: 40px 24px 64px;
    width: 100%;
    box-sizing: border-box;
}

.containerStickToTheRightMobile {
}

.closeButtonContainer {
    position: absolute;
    z-index: 1;
    transform: translate(0%, -44%);

    right: 16px;
    width: 36px;
    height: 36px;
}

.closeButton {
    background-image: url('icon_closebg.svg');
    top: 24px;
    right: 24px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    background-color: transparent;
    border: none;
    outline: none;
    background-size: 36px;
    background-repeat: no-repeat;
}

.modalHeader {
    text-align: center;
    margin: 0 52px;
}

.modalTitle {
    display: inline-block;
    margin-bottom: 8px;
}

.modalSubTitle {
    color: var(--scoutNavy);
}

.content {
    z-index: 0;
}

.leftAligned {
    text-align: left;
}

@media tablet {
    .modal {
        padding: 48px;
    }

    .closeButtonContainer {
        top: 24px;
        transform: translate(0%, 0%);
    }

    .container {
        border-radius: 10px;
        padding: 40px 48px 64px;
        width: var(--w14);
    }
}

@media desktop {
    .modal {
        padding: 80px 0;
    }

    .container {
        padding: 56px 80px 64px;
        width: var(--w12);
    }
}
