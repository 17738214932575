@value b-desktop from '../breakpoints.css';

.body1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--scoutNavy);
}

@media b-desktop {
  .body1 {
    font-size: 18px;
    line-height: 24px;
  }
}