@value desktop from '~breakpoints';

.loaderScreen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loaderScreen h5 {
    text-align: center;
}

.loading {
    text-align: center;
}

.ring {
    width: 84px;
    height: 84px;
}

.ring div {
    border-width: 6px;
}

.header, .marvelNavHeader, .marvelHeader {
    width: 100%;
    padding: 52px var(--w1) 48px;
}

.marvelHeader {
    padding: 0 var(--w1) 48px;
}

.marvelNavHeader {
    padding: 52px var(--w1) 40px;
}

.header {
    background: var(--bluesCluesBlue);
}

.marvelHeader, .marvelNavHeader {
    background: var(--huckleberryBlue);
}

.nav {
    display: flex;
    align-items: flex-end;
    color: var(--zeroWhite);
}

.nav > div {
    display: flex;
    align-items: flex-end;
    gap: 0 8px;
}

.nav img {
    width: 96px;
}

.nav p {
    font-size: 14px;
    line-height: 16px;
}

.clinic {
    font-size: 14px;
    line-height: 16px;
}

.nav a {
    color: var(--zeroWhite);
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
}

.nav a::before {
    content: '|';
    color: var(--zeroWhite);
    padding-right: 8px;
}

.header h1, .marvelHeader h1 {
    max-width: 511px;
    color: var(--white);
    text-align: center;
    margin: 28px auto 32px;
    font-size: 30px;
    line-height: 36px;
}

.marvelHeader h1 {
    margin: 0 auto 32px;
}

.container {
    margin: 40px auto;
}

.content {
    padding: 0 var(--w1);
    display: flex;
    flex-direction: column;
    gap: 24px var(--w1);
    max-width: 1260px;
    /* max-width 14/16 columns at 1440px screen width */
    margin: 0 auto;
}

.image {
    width: 272px;
    margin: 0 auto;
}

.widget {
    background: var(--cujoCream);
    flex: 1;
    border-radius: 8px;
    padding: 44px 40px 30px;
}

.photo {
    margin: 0 auto 14px;
    width: 100%;
}

.desktopText {
    color: var(--zeroWhite);
    font-weight: 700;
}

.mobileAction {
    text-align: center;
    color: var(--zeroWhite);
}

.desktopAction {
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktopAction:hover {
    text-decoration: none;
}

.download {
    width: 24px;
    margin-right: 8px;
    filter: brightness(0) invert(1);
}

.hidden {
    display: none;
}

.arrow, .ribbon {
    display: none;
}

.powered {
    margin: 40px auto;
    width: 124px;
}

@media desktop {
    .loaderScreen {
        height: 340px;
    }

    .header, .marvelHeader {
        padding: 22px var(--w1) 80px;
    }

    .marvelNavHeader {
        padding: 22px var(--w1) 34px;
    }

    .nav {
        justify-content: space-between;
    }

    .nav img {
        width: 120px;
    }

    .nav p {
        font-size: 20px;
        line-height: 24px;
    }

    .nav a {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .nav a::before {
        content: none;
    }

    .header h1, .marvelHeader h1 {
        font-size: 36px;
        line-height: 44px;
        max-width: 100%;
        margin: 52px auto 46px;
    }

    .marvelHeader h1 {
        margin: 0 auto 46px;
    }

    .content {
        flex-direction: row;
        align-items: flex-start;
    }

    .image {
        width: 320px;
    }

    .widget {
        position: relative;
    }

    .arrow, .ribbon {
        position: absolute;
        display: block;
    }

    .arrow {
        fill: var(--cujoCream);
        width: 24px;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
    }

    .ribbon {
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-2deg);

        background: var(--cliffordRed);
        color: var(--zeroWhite);
        padding: 2px 8px;
        font-weight: 800;
    }
}