.container {
    padding: 0 0 56px;
    display: flex;
    flex-wrap: wrap;
}

.content {
    background-color: var(--bluesCluesBlue);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.contentLeft {
    padding: 22px 24px 24px;
    display: flex;
    flex-direction: column;
}

.title, .subtitle {
    color: var(--zeroWhite);
    font-size: 18px;
    line-height: 24px;
    font-family: var(--main-font);
}

.title {
    font-weight: 800;
    margin-bottom: 8px;
}

.subtitle {
    font-weight: 600;
    margin-bottom: 16px;
}

.button {
    width: 176px;
}

.buttonText {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}

.buttonText > img {
    height: 20px;
}

.image {
    padding: 9px 39px 5px 16px;
}

.memeContainer {
    display: flex;
    margin-right: 66px;
    align-items: baseline;
    min-width: 274px;
}

.memeText {
    height: 88px;
    width: 88px;
    margin-right: 18px;
}

.meme {
    height: 186px;
    width: 186px;
    transform: rotate(7.5deg);
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.35));
}

.requestItems {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.requestedText {
    margin-left: 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-style: italic;
    color: var(--zeroWhite);
}

.link {
    text-decoration: none;
    color: inherit;
}

.link:hover {
    text-decoration: none;
}