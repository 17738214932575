@value b-tablet-only, b-desktop-only from '~breakpoints';

.container {
    padding: 120px var(--column1);
    position: relative;
    min-height: calc(100vh - 72px);
    overflow: hidden;
}

.title {
    color: var(--puppyPurple);
    margin-bottom: 20px;
}

.content {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
}

.button {
    width: 100%;
}

.link {
    text-decoration: none;
    color: inherit;
}

.link:hover {
    text-decoration: none;
}

.contactTitle {
    margin-top: 48px;
    margin-bottom: 16px;
}

.social {
    width: 120px;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 70px;
}

.icon {
    height: 29px;
}

.yellowCircle,
.blueCircle,
.redCircle {
    border-radius: 50%;
    position: absolute;
}

.yellowCircle {
    background: var(--plutoGold);
    width: 43px;
    height: 43px;
    top: 40px;
    left: var(--column1);
}

.blueCircle {
    background: var(--puppyPurple);
    width: 34px;
    height: 34px;
    bottom: 125px;
    right: var(--column1);
}

.redCircle {
    background: var(--kittyPink);
    width: 56px;
    height: 56px;
    bottom: 45px;
    right: calc(var(--column1) + 46px);
}

.cat {
    position: absolute;
    width: 280px;
    top: calc(100% - 180px);
    left: calc(var(--column1) - 20px);
}

@media b-tablet-only {
    .content {
        width: 385px;
    }

    .button {
        width: 313px;
    }

    .blueCircle {
        bottom: unset;
        top: 40px;
        width: 46px;
        height: 46px;
    }

    .redCircle {
        bottom: unset;
        top: 100px;
        width: 76px;
        height: 76px;
    }

    .cat {
        left: unset;
        right: calc(var(--column1) - 130px);
        top: calc(100% - 270px);
        width: 420px;
    }
}

@media b-desktop-only {
    .container {
        padding-top: 150px;
    }

    .content {
        width: 510px;
        font-size: 24px;
        line-height: 32px;
    }

    .button {
        width: 360px;
    }

    .yellowCircle {
        width: 65px;
        height: 65px;
        top: 45px;
    }

    .blueCircle {
        bottom: unset;
        top: 40px;
        width: 62px;
        height: 62px;
    }

    .redCircle {
        bottom: unset;
        top: 100px;
        width: 102px;
        height: 102px;
    }

    .cat {
        left: unset;
        right: calc(var(--column1) - 160px);
        top: calc(100% - 330px);
        width: 540px;
    }
}