@value b-desktop from '~breakpoints';

.menu {
    width: 100%;
    background: var(--bluesCluesLow);
    padding: 40px var(--column1);
    overflow-y: hidden;
}

.backButton {
    display: flex;
    align-items: center;
    padding: 0;
    color: var(--scoutNavy);
    min-height: 0;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.chevron {
    margin-right: 8px;
    transform: rotate(180deg);
    height: 12px;
}

.title {
    margin-top: 40px;
}

.info {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.infoIcon {
    margin-right: 8px;
}

.desktop {
    display: none;
}

.accordionContainer {
    width: 100%;
    padding: 30px var(--column1);
}

.accordionBackground {
    width: 100%;
    padding: 30px var(--column1);
    background: var(--bluesCluesLow);
}

.goBackMobileOnly {
    width: 100%;
    padding: 30px var(--column1) 0;
}

@media b-desktop {
    .menu {
        width: var(--column4);
        padding: 40px;
        box-sizing: content-box;
    }

    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}