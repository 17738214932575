@value b-mobile, b-desktop from '~breakpoints';

.modalContainer {
    border-radius: 0;
}

.modalContent {
    height: 100%;
    padding: 72px var(--column1) 24px;
}

.carouselTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.carouselTitleIcon {
    width: 35px;
    height: 45px;
    margin-right: 8px;
}

.carouselItemContent {
    height: 100%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.carouselItemImage {
    margin: 0 auto 32px;
}

.carouselItemButton {
    width: 140px;
    margin: 32px auto 0;
}

@media b-desktop {
    .modalContainer {
        height: 70vh;
        max-height: 620px;
        min-width: 768px;
        width: 860px;
    }

    .carouselTitleIcon {
        margin-right: 12px;
    }
}

@media b-mobile {
    .carouselTitle h4 {
        max-width: 210px;
    }
}
