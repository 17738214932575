@value tablet, desktop from '~breakpoints';

.root {
    display: flex;
    flex-direction: column;
    padding: 0 var(--w1);
    text-align: center;
    color: var(--scoutNavy);
}

.left {
    width: 100%;
}

.left h2 {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 38px;
}

.digIn {
    margin-top: 18px;
    margin-bottom: 34px;
}

.traits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 18px 0;
    margin: 0 0 51px;
}

.trait {
    flex: 40%;
}

.trait img {
    margin: 0 auto 14px;
    height: 40px;
}

.trait p {
    font-size: 18px;
    line-height: 28px;
    color: var(--scoutNavy);
}

.lifestages h3 {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 32px;
}

.lifestage {
    margin-top: 16px;
    padding: 16px;
    border: 1px solid var(--benjiBlue);
    border-radius: 16px;
    display: flex;
    gap: 0 24px;
}

.activeAge {
    padding: 13px;
    border: 4px solid var(--bluesCluesBlue);
}

.lifestageImg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 18px;
}

.lifestageCopy {
    position: relative;
}

.lifestageTitle {
    font-family: var(--heading-font);
    font-size: 18px;
    line-height: 28px;
    color: var(--scoutNavy);
    display: flex;
    align-items: center;
    gap: 0 2px;
}

.lifestage span {
    background: var(--bluesCluesBlue);
    color: var(--white);
    font-size: 10px;
    line-height: 18px;
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 800;
    margin-left: 4px;
}

.accordionContainer {
    width: 100%;
}

.accordionContent {
    box-shadow: none;
    position: relative;
    top: initial;
    margin-top: 18px;
}

.accordionContent>* {
    border-bottom: none;
}

.right {
    width: 100%;
    margin-top: 98px;
}

.aside {
    background-color: var(--peteyBlue);
    width: 100%;
    position: relative;
}

.info {
    padding: 38px var(--w1) 32px;
}

.info p {
    font-size: 14px;
    line-height: 22px;
    color: var(--scoutNavy);
}

.info p {
    margin-bottom: 18px;
}

.button {
    width: 100%;
}

.ribbon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--bluesCluesBlue);
    color: var(--white);

    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    z-index: 1;
    text-align: center;
    min-width: 267px;
    padding: 8px 0 4px;
}

.aside img {
    position: absolute;
    top: -44px;
    left: 50%;
    width: 42px;
    transform: rotate(-8deg) translate(-50%, -50%);
    z-index: 2;
}

.desktopOnly {
    display: none;
}

.reasons {
    margin-bottom: 63px;
}

.reasons h3 {
    flex: 100%;
    margin-bottom: 18px;
}

.reason {
    display: flex;
    margin-bottom: 18px;
    justify-content: center;
}

.reason h4 {
    display: none;
}

.reason p {
    margin-bottom: 18px;
}

@media tablet {
    .root {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    .left {
        width: var(--w8);
    }

    .left h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .traits {
        flex-wrap: nowrap;
        margin: 32px 0 72px;
    }

    .trait {
        flex: 1;
    }

    .trait img {
        margin: 0 0 14px;
    }

    .lifestages h3 {
        font-size: 30px;
        line-height: 38px;
    }

    .lifestageImg {
        flex: 0 0 200px;
        margin: 0 0 13px 0;
    }

    .right {
        width: var(--w3);
        margin-top: 0;
    }

    .ribbon {
        left: 37%;
        text-align: left;
        min-width: auto;
        width: 157px;
        transform: translate(max(-6vw, -90px), -50%);
        padding: 4px 8px 4px 16px;
    }

    .info {
        padding: 38px 24px 32px;
    }

    .aside img {
        visibility: hidden;
    }

    .reasons h3 {
        flex: initial;
        margin-right: 12px;
    }

    .reason {
        justify-content: left;
    }

    .reason h4 {
        color: var(--zeroWhite);
        background: var(--bluesCluesBlue);
        margin-right: 22px;

        display: flex;
        flex: 0 0 38px;
        height: 36px;
        justify-content: center;
        align-items: center;
        transform: rotate(-2deg);
    }
}

@media desktop {
    .aside img {
        visibility: visible;
        left: 4px;
        top: -8px;
    }

    .mobileOnly {
        display: none;
    }

    .desktopOnly {
        display: block;
    }

    .lifestage span {
        position: absolute;
        top: 0;
        right: 0;
    }
}