.root {
    display: flex;
}

.base {
    height: 800px;
    width: 800px;
    background-color: var(--bluesCluesBlue);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: 0 0 0 100vmax var(--bluesCluesBlue);
}

.pill {
    background-color: var(--zeroWhite);
    color: var(--bluesCluesBlue);
    font-weight: 800;
    padding: 4px 16px;
    display: inline-block;
    border-radius: 16px;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.root h1 {
    font-size: 40px;
    line-height: 48px;
    color: var(--zeroWhite);
    font-weight: 200;
    margin: 0 auto 16px;
}

.root h1 span {
    font-weight: 800;
}

.legal {
    color: var(--zeroWhite);
    margin: 0 auto;
}

.root h1, .legal {
    max-width: 712px;
}