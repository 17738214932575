.container {
    height: 100vh;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.logo {
    margin-bottom: 60px;
}

.icon {
    width: 134px;
    height: 134px;
}

.header {
    margin: 40px auto;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
}

.subheader, .message {
    font-size: 24px;
    line-height: 28px;
}

.subheader {
    font-weight: 800;
    margin-bottom: 10px;
}

.message {
    font-weight: 400;
}