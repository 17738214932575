@value tablet from '~breakpoints';

.underwriting {
    font-size: 16px;
    color: var(--white);
}

.root section {
    padding: 42px 0;
    border-bottom: 1px solid var(--pusheenGray);
    max-width: 1440px;
    margin: 0 auto;
}

@media tablet {
    .root section {
        padding: 60px var(--w1) 80px;
    }
}