:root {
  /* 2022 library compliant colors https://www.figma.com/file/Ak2atV5PRgGqVOyECDOFyz/%F0%9F%8E%A8-Pumpkin-UI-Library-2022 */
  /* blues */
  --snoopyBlue: #2839A8;
  --dinoBlue: #2A41DE;
  --bluesCluesBlue: #5368F5;
  --huckleberryBlue: #92A6FF;
  --benjiBlue: #CBD2FC;
  --peteyBlue: #EEF0FE;

  /* reds */
  --rowlfRed: #C21B44;
  --crookshanksRed: #FA4154;
  --cliffordRed: #F7636F;
  --couragePink: #FFA4AC;
  --cheshirePink: #FFD1D5;
  --gidgetPink: #FEEFF1;

  /* grays */
  --scoutNavy: #373F51;
  --gromitGray: #6B717E;
  --shilohGray: #9B9FA8;
  --pusheenGray: #CDCFD3;
  --porkchopGray: #EBECEE;

  /* yellows */
  --spotYellow: #FEC600;
  --plutoGold: #FEE300;
  --oldYellerYellow: #FFF28C;
  --hobbesYellow: #FFF9CC;

  /* greens */
  --maxGreen: #008143;
  --astroGreen: #3EC28F;
  --sparkyGreen: #9EE0C7;
  --wishboneGreen: #CEEFE3;

  /* whites */
  --cujoCream: #F8F7F3;
  --zeroWhite: #FFFFFF;

  /* deprecated, do not add here */
  --softGrey: #EEEFF1;
  --grey: #C4C4C4;
  --mediumGrey: #7A818F;
  --deepGrey: #373F51;
  --darkGrey: #949494;
  --lightGrey: #6D737E;
  --mellowYellow: #FEE300;
  --plutoBlue: #0B33E3;
  --kittyPink: #FFA4AC;
  /*v-cliffordRed at 0.6 opacity on a white background*/
  --bluesClues: #5368F5;
  --bluesCluesLight: #93A6FF;
  /*v-bluesClues at 0.6 opacity on a white background*/
  --scoutNavyLight: #737985;
  /*v-scoutNavy at 0.7 opacity on a white background*/
  --plutoGoldLight: #FFFAAC;
  /*v-plutoGold at 0.2 opacity on a white background*/
  --greyCream: #F8F8F6;
  --cream: #F8F7F3;
  --white: #FFFFFF;
  --pink: #F08395;
  --pinkRed: #FF4E5D;
  --pinkLight: #FFD1D5;
  --royal: #0B33E3;
  --primaryRed: #FB5D3E;
  --primaryGreen: #3EC28F;
  --black: #000000;
  --red: #E84D2A;
  --redAlert: #FB5D3E;
  --purpleLight: #DEE4FF;
  --periwinkle: #EEF1FF;
  --peteyPurple: #EEF0FE;
  --puppyPurple: #92A6FF;

  /* colors used in pre-2022 library designs not specified in UI Library */
  --bluesCluesLow: rgba(83, 104, 245, 0.05);
  --bluesCluesMedium: rgba(222, 228, 255, 0.7);
  --creamOverlay: rgba(248, 247, 243, 0.5);
  --orange: #E39310;
  --lightYellow: #FFF9CC;
  --greyLight: #F2F2F2;
  --scoutNavyHalf: #9B9FA7;

  /* RGB Values for Alpha Manipulation */
  --scoutNavyRgb: 55,63,81;
}

.inject {
  display: none;
}