@value b-desktop from '~breakpoints';

.inactive {
    opacity: 0.6;
}

.insuranceLink {
    flex: 100%;
    font-size: 14px;
    color: var(--royal);
    margin-top: 10px;
    text-align: left;
    padding: 0;
    min-height: 0;
}

@media b-desktop {
    .insuranceLink {
        flex: 1;
        text-align: right;
        margin-top: 0;
    }
}