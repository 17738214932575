.container {
    margin: 10px 0 20px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.leftArrow {
    transform: rotate(180deg);
}

.notAllowed {
    opacity: 0.3;
}

.page {
    padding: 0;
    min-height: 0;
    width: 24px;
    height: 24px;
    margin: 0 4px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    background: var(--puppyPurple);
    color: white;
    border-radius: 50%;
}