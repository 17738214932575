.inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px 0;
}

.row {
    flex-direction: row;
}

.inputs.row {
    gap: 0 16px;
}

.error {
    color: var(--crookshanksRed);
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    flex: 100%;
    margin-top: 10px;
}