@value b-desktop from '~breakpoints';

.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    min-height: calc(100vh - 352px);
    /* 72px header, 280px footer */
}

.content {
    width: 100%;
    padding: 0 var(--column1);
}

.header {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.claimsResultContainer {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.desktop {
    display: none;
}

.activePetInfo {
    display: flex;
    align-items: center;
}

.activePetImage {
    margin-right: 16px;
    height: 64px;
    width: 64px;
}

.activePetHeader {
    display: flex;
    align-items: center;
}

.headerName {
    font-size: 14px;
    font-weight: 800;
    color: var(--scoutNavy);
}

.headerSlash {
    margin: 0 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--scoutNavyLight);
}

.headerLabel {
    font-size: 14px;
    font-weight: 600;
    color: var(--scoutNavyLight);
}

@media b-desktop {
    .container {
        flex-direction: row;
    }

    .content {
        padding: 40px;
    }

    .desktop {
        display: block;
    }
}