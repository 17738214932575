@value tablet from '~breakpoints';

.root {
    padding: 0 var(--w1);
    color: var(--scoutNavy);
    text-align: center;
    max-width: 1260px;
    /* 14 columns at 1440px width */
    margin: 42px auto;
}

.root h2 {
    margin: 0 auto 20px;
    max-width: 800px;
    font-size: 30px;
    line-height: 38px;
}

.subtitle {
    margin: 0 auto 24px;
    max-width: 830px;
    font-size: 16px;
    line-height: 24px;
}

.block {
    background-color: var(--cujoCream);
    padding: 32px 16px 4px;
    border-radius: 8px 8px 0 0;
}

.case {
    display: flex;
    flex-direction: column;
}

.case img {
    width: 176px;
    margin: 0 auto 24px;
}

.copy > p {
    font-size: 18px;
    line-height: 24px;
}

.finances {
    display: flex;
    flex-wrap: wrap;
}

.finances div {
    flex: 40%;
    border-top: 1px solid var(--pusheenGray);
    margin-top: 32px;
    padding-top: 18px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.finances div p:first-child {
    max-width: 110px;
    margin: 0 auto;
}

.finances b {
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.number {
    font-family: var(--heading-font);
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
}

.finances div:last-child .number {
    color: var(--bluesCluesBlue);
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}

.priceBlock {
    background: var(--cujoCream);
    padding: 16px;
    border-radius: 0 0 8px 8px;
}

.review {
    background: var(--zeroWhite);
    padding: 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 14px 0;
}

.info p:first-child, .redirectBlock p:first-child {
    font-weight: 800;
    color: var(--scoutNavy);
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
}

.redirectBlock p:first-child {
    font-family: var(--heading-font);
}

.info p, .redirect p {
    font-size: 14px;
    line-height: 22px;
    color: var(--gromitGray);
    text-transform: capitalize;
}

.customize, .quote {
    border-radius: 4px;
    width: 100%;
}

.quote {
    margin-top: 24px;
}

.legal {
    font-size: 14px;
    line-height: 22px;
    color: var(--gromitGray);
    max-width: 680px;
    padding: 16px 0 0;
    margin: 0 auto;
}

.reimbursementBlock {
    background: var(--cujoCream);
    padding: 24px 0 20px;
    border-radius: 0 0 8px 8px;
}

.reimbursementBlock > div {
    border-top: 1px solid var(--pusheenGray);
}

.redirectBlock {
    background-color: var(--cujoCream);
    padding: 32px 16px;
    border-radius: 8px;
    margin-top: 18px;
}

@media tablet {
    .root {
        margin-top: 77px;
        margin-bottom: 120px;
    }

    .root h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .subtitle {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 28px;
    }

    .block {
        padding: 32px var(--w1) 16px;
        text-align: left;
    }

    .case {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap: 0 var(--w1);
    }

    .case img {
        width: 270px;
        flex: 0 0 270px;
        margin: 0;
    }

    .copy {
        flex: 1;
    }

    .finances div {
        margin-top: 40px;
        padding-top: 36px;
        flex: 30%;
    }

    .finances div p:first-child {
        max-width: 120px;
        margin: 0;
    }

    .finances b {
        font-size: 18px;
        line-height: 24px;
    }

    .number {
        font-weight: 300;
    }

    .number, .finances div:last-child .number {
        font-size: 36px;
        line-height: 44px;
    }

    .priceBlock {
        padding: 24px;
    }

    .review {
        padding: 24px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    .customize {
        width: 164px;
    }

    .quote {
        width: 242px;
    }

    .redirectBlock {
        padding: 24px 32px 40px;
    }
}