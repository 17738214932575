@value b-desktop from '../breakpoints.css';

.bodySubhead {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: var(--scoutNavy);
}

@media b-desktop {
  .bodySubhead {
    font-size: 16px;
    line-height: 24px;
  }
}