@value b-desktop, b-desktop-only, b-desktop-large from '~breakpoints';

.header {
    background-color: var(--bluesCluesMedium);
}

.container {
    padding: 64px var(--column1);
    max-width: 1440px;
    margin: 0 auto;
}

.content {
    max-width: 637px;
}

.topic {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
}

.topic img {
    margin-right: 8px;
    width: 22px;
}

.legalBody {
    font-size: 16px;
}

.button {
    width: 100%;
    max-width: 360px;
    margin: 32px 0 0;
    padding: 22px 68px;
}

.subText {
    max-width: 360px;
    font-size: 12px;
    line-height: 16px;
    padding-top: 40px;
    opacity: 1;
}

@media b-desktop {
    .container {
        padding-top: 110px;
    }

    .topic img {
        width: 24px;
    }

    .legalBody {
        font-size: 18px;
    }

    .subText {
        padding-top: 32px;
    }
}

@media b-desktop-only {
    .container {
        padding-bottom: 40px;
        background: url('images/illustrations/multipet/preventive-essentials.png') no-repeat 90% 100%;
        background-size: 45% auto;
    }

    .subText {
        padding-top: 56px;
    }
}