@value b-tablet-only, b-desktop from '~breakpoints';

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.toggles {
    display: flex;
    justify-content: center;
    margin: 40px auto 24px;
    flex: 100%;
}

.leftButton,
.rightButton {
    width: 124px;
    height: 36px;
    padding: 0;
    border-color: var(--scoutNavyLight);
    font-weight: 800;
    display: flex;
    justify-content: center;
}

.leftButton {
    border-radius: 10px 0 0 10px;
    border-right-width: 0;
}

.rightButton {
    border-radius: 0 10px 10px 0;
    border-left-width: 0;
}

.mobileHeader {
    text-align: center;
    margin-bottom: 24px;
    flex: 100%;
}

.image {
    max-height: 393px;
    max-width: 314px;
    width: 100%;
    margin: 0 auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--creamOverlay);
    height: 393px;
    width: 314px;
}

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ring {
    width: 63px;
    height: 63px;
}

.ring div {
    border-width: 4px;
}

.data {
    padding-top: 32px;
    max-width: 314px;
    width: 100%;
    margin: 0 auto;
}

.infoContainer {
    display: flex;
    align-items: flex-start;
}

.icon {
    width: 14px;
    margin: 2px 10px 0 0;
}

.primaryIcon {
    filter: grayscale(1);
}

.button:hover .primaryIcon,
.button:hover .secondaryIcon {
    filter: brightness(0) invert(1);
}

.button {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

@media b-desktop {
    .modal {
        width: var(--column8);
        min-height: auto;
        max-width: 682px;
    }

    .modalContent {
        padding: 80px 0 88px;
    }

    .container {
        flex-direction: row;
        justify-content: center;
    }

    .mobileHeader {
        margin-top: 0;
    }

    .toggles {
        margin-top: 0;
    }

    .image {
        width: 267px;
        height: 334px;
    }

    .data {
        width: 250px;
        margin: 0 0 0 40px;
    }

    .text {
        font-size: 14px;
        line-height: 20px;
    }
}

@media b-tablet-only {
    .modal {
        width: var(--column9);
    }

    .data {
        margin-left: 30px;
        width: 235px;
    }

    .image {
        width: 237px;
        height: 296px;
    }
}