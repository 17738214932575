.container {
    position: relative;
    padding: 0;
}

/* to hide the native image input on top of our designed button */
.input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}