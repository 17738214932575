.container {
    width: var(--column10);
    max-width: 350px;
    margin: 40px auto 0;
    min-height: 70vh;
    padding-bottom: 120px;
}

.image {
    margin: 0 auto;
}

.title {
    margin: 28px auto 60px;
    text-align: center;
}

.button {
    display: block;
    margin: 24px auto 0;
    width: 100%;
}

.link,
.requestAccess a {
    text-decoration: none;
    color: var(--royal);
    font-weight: 600;
}

.link,
.requestAccess {
    margin-top: 20px;
    display: block;
    text-align: center;
    font-size: 14px;
}

.error {
    margin-top: 20px;
    font-size: 14px;
    color: var(--redAlert);
    text-align: center;
}