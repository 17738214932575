/**
 * Current breakpoints are: mobile, tablet and desktop.
 */
@value mobile: screen and (max-width: 768px);
@value tablet: screen and (min-width: 768px);
@value desktop: screen and (min-width: 1280px);

:root {
    --w1: min(100vw * 1/16, 1440px * 1/16);
    --w2: min(100vw * 2/16, 1440px * 2/16);
    --w3: min(100vw * 3/16, 1440px * 3/16);
    --w4: min(100vw * 4/16, 1440px * 4/16);
    --w5: min(100vw * 5/16, 1440px * 5/16);
    --w6: min(100vw * 6/16, 1440px * 6/16);
    --w7: min(100vw * 7/16, 1440px * 7/16);
    --w8: min(100vw * 8/16, 1440px * 8/16);
    --w9: min(100vw * 9/16, 1440px * 9/16);
    --w10: min(100vw * 10/16, 1440px * 10/16);
    --w11: min(100vw * 11/16, 1440px * 11/16);
    --w12: min(100vw * 12/16, 1440px * 12/16);
    --w13: min(100vw * 13/16, 1440px * 13/16);
    --w14: min(100vw * 14/16, 1440px * 14/16);
    --w15: min(100vw * 15/16, 1440px * 15/16);
    --w16: min(100vw, 1440px);
}

.inject {
    display: none;
}

/**
* @deprecated Please use mobile, tablet and desktop instead!
*/

@value b-mobile: screen and (max-width: 767px);
@value b-desktop: screen and (min-width: 768px);
@value b-tablet-only: screen and (min-width: 768px) and (max-width: 1023px);
@value b-desktop-only: screen and (min-width: 1024px);

:root {
    --column1: min(100vw * 1/12, 120px);
    --column2: min(100vw * 2/12, 240px);
    --column3: min(100vw * 3/12, 360px);
    --column4: min(100vw * 4/12, 480px);
    --column5: min(100vw * 5/12, 600px);
    --column6: min(100vw * 6/12, 720px);
    --column7: min(100vw * 7/12, 840px);
    --column8: min(100vw * 8/12, 960px);
    --column9: min(100vw * 9/12, 1080px);
    --column10: min(100vw * 10/12, 1200px);
    --column11: min(100vw * 11/12, 1320px);
    --column12: min(100vw, 1440px);
}

@value downXl: (max-width: 1920px);
@value downLg: (max-width: 1920px);
@value downMd: (max-width: 1280px);
@value downSm: (max-width: 960px);
@value downXs: (max-width: 600px);
@value upXl: (min-width: 1920px);
@value upLg: (min-width: 1920px);
@value upMd: (min-width: 1280px);
@value upSm: (min-width: 960px);
@value upXs: (min-width: 600px);