@value tablet from '~breakpoints';

.root {
    background: var(--bluesCluesBlue);
    width: 100%;
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.root img {
    margin: -32px auto 32px;
    width: 242px;
}

.root p {
    font-family: var(--heading-font);
    font-weight: 700;
    letter-spacing: -0.5px;
    color: white;
    font-size: 24px;
    line-height: 32px;
    width: 272px;
    margin: 0 auto;
    text-align: center;
}

@media tablet {
    .root {
        height: calc(100vh - 80px);
        justify-content: initial;
    }

    .root img {
        margin: 158px auto 52px;
        width: 324px;
    }

    .root p {
        font-size: 30px;
        line-height: 36px;
        width: 100%;
    }
}