@value b-desktop, b-mobile from '~breakpoints';

.module {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 24px;
    margin: 24px 0 60px;
}

.leftContainer {
    height: 144px;
    width: 144px;
    margin-bottom: 24px;
}

.banner {
    border-radius: 10px;
    max-width: max-content;
}

.link {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
}

.rightContainer {
    display: flex;
    flex-direction: column;
}

.name {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;
}

.header {
    margin-bottom: 24px;
    font-size: 14px;
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.button,
.action {
    width: 100%;
    padding: 15px 0;
}

.popoverButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 14px;
    color: var(--royal);
    font-weight: bold;
    padding: 0;
    width: 100%;
    justify-content: center;
}

.popoverButton img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.popover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 8px 0;
    gap: 2px;
    display: flex;
    flex-direction: column;
}

.popover>* {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    min-width: 170px;
    width: 100%;
    height: 48px;
    color: var(--scoutNavy);
}

.popover * {
    font-weight: normal;
}

.popover>*:hover {
    background: var(--bluesCluesLow);
}

.popover>* img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.tooltip {
    width: 296px;
}


@media b-desktop {
    .module {
        flex-direction: row;
    }

    .leftContainer {
        margin-right: 24px;
        margin-bottom: 0;
    }

    .rightContainer {
        max-width: 544px;
    }

    .button,
    .action {
        width: 160px;
    }

    .tooltip {
        bottom: revert;
        top: 100%;
    }

    .popoverButton {
        width: unset;
    }
}

@media b-mobile {
    .tooltip {
        left: 50%;
    }
}

@media screen and (max-width: 510px) {
    .buttons {
        flex-direction: column;
    }
}