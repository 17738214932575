.container {
    padding: 50px var(--column1) 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-right: 10px;
}

.notesContainer, .reimbursementNote {
    max-width: 650px;
    margin: 32px auto;
    text-align: center;
}

.notes:not(:first-child) {
    margin-top: 30px;
}

.button {
    width: 100%;
    max-width: 285px;
    margin: 0 auto;
}

.link {
    text-decoration: none;
    color: inherit;
}

.link:hover {
    text-decoration: none;
}

.reimbursementNote {
    opacity: 1;
}