@value tablet from '~breakpoints';

.underwriting {
    font-size: 16px;
    color: var(--white);
}

.blue {
    background-color: var(--bluesClues);
    width: 100%;
}

.header {
    display: flex;
    padding: 0 var(--w1) 66px;
    max-width: 1440px;
    margin: 0 auto;
}

.text * {
    color: var(--white);
}

.header h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    letter-spacing: 0;
}

.header h1 span {
    color: var(--couragePink);
}

.header .subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
}

.header ul {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
}

.header li {
    text-decoration: none;
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    width: 325px;
}

.iconContainer {
    margin-right: 16px;
    flex-shrink: 0;
}

.iconContainer img {
    width: 48px;
}

.header > img {
    display: none;
}

.content {
    padding: 32px var(--w1) 64px;
    max-width: 1440px;
    margin: 0 auto;
}

.stepperRoot {
    padding-left: var(--w1);
    padding-right: var(--w1);
}

.stepperImg {
    width: 48px;
    height: 48px;
}

.desktop {
    display: none;
}

.content h3 {
    color: var(--bluesCluesBlue);
    font-size: 24px;
    line-height: 32px;
    margin: 42px auto 46px;
    font-weight: 700;
    text-align: center;
    width: 272px;
    height: 56px;
}

.question {
    font-weight: 800;
    color: var(--scoutNavy);
    font-size: 18px;
    line-height: 28px;
}

.question sup {
    color: var(--cliffordRed);
    margin-left: 4px;
}

.radioGroup {
    margin: 6px 0 30px;
    gap: 0 16px;
}

.radio {
    flex: 1 1 40%;
    height: 144px;
    padding: 15px 0;
}

.radioChecked {
    padding: 14px 0 !important;
}

.radioLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px 0;
}

.radioRadio {
    top: 15px !important;
}

.radioImage {
    top: 14px !important;
}

.radioLabel img {
    height: 72px;
    padding-top: 12px;
}

.textField {
    margin-top: 6px;
}

.button {
    margin: 14px auto 0;
    width: 100%;
    display: block;
    border-radius: 4px;
}

.legalPrivacy {
    font-style: italic;
    color: var(--scoutNavy);
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
}

.legalPrivacy a {
    color: var(--bluesCluesBlue);
    text-decoration: none;
}

.legalPrivacy a:hover {
    text-decoration: underline;
}

@media tablet {
    .header {
        padding: 0 var(--w2) 66px;
        justify-content: space-between;
        align-items: flex-start;
        gap: 0 var(--w1);
    }

    .header h1 {
        padding-top: 28px;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
        max-width: 455px;
    }

    .header .subtitle {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
        width: unset;
    }

    .header li {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        max-width: 450px;
    }

    .iconContainer {
        margin-right: 24px;
    }

    .header > img {
        display: block;
        max-width: 420px;
        width: 50%;
    }

    .content {
        padding: 32px var(--w4) 80px;
    }

    .stepperRoot {
        padding-left: 0;
        padding-right: 0;
    }

    .stepperImg {
        width: 56px;
        height: 56px;
    }

    .desktop {
        display: inline;
    }

    .content h3 {
        font-size: 30px;
        line-height: 36px;
        margin: 36px auto 30px;
        width: 100%;
        height: 45px;
    }

    .content form {
        width: var(--w6);
        margin: 0 auto;
    }

    .radioGroup {
        margin-top: 14px;
    }

    .button {
        margin: 30px auto 0;
        width: 256px;
    }
}