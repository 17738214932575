.root {
    width: 800px;
    height: 800px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.root:before {
    content: '';
    background: url('pink_paws.png');
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
}

.root img {
    width: 640px;
    height: 640px;
    filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.25));
}