.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    position: relative;
    flex: 1;
}

.label {
    color: var(--scoutNavy);
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    top: 0;
    transition: top 0.1s, font-size 0.1s, line-height 0.1s;
}

.label.placeholder {
    font-size: 14px;
    line-height: 20px;
    top: 30px;
    left: 15px;
}

.textAreaContainer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.textArea {
    padding: 20px 15px;
    color: var(--scoutNavy);
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    border: 1px solid var(--grey);
    border-radius: 8px;
    box-sizing: content-box;
    text-align: left;
    resize: none;
}

.textArea::placeholder {
    visibility: hidden;
}

.error {
    color: var(--redAlert);
    border-color: var(--redAlert);
}

.errorMessage {
    color: var(--redAlert);
    font-size: 12px;
    margin-top: 8px;
    min-height: 20px;
}

.asterisk {
    color: var(--redAlert);
}

.readOnly {
    border-color: var(--scoutNavyHalf);
    color: var(--scoutNavyHalf);
}

.hidden {
    display: none;
}