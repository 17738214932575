@value b-desktop from '~breakpoints';

.container {
    width: var(--column10);
    background: var(--white);
    padding: 26px 32px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.container:hover {
    text-decoration: none;
}

.container,
.container>*:not(:last-child) {
    margin-bottom: 16px;
}

.petItem {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.petName {
    width: 280px;
    text-overflow: ellipsis;
}

.petProducts {
    flex: 100%;
    text-align: right;
}

.gray {
    filter: grayscale(100%) opacity(25%);
}

@media b-desktop {
    .container {
        width: var(--column6);
    }
}