@value tablet from '~breakpoints';

.root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 34px;
}

.step {
    position: relative;
}

.step p {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);

    font-size: 14px;
    color: var(--gromitGray);
    font-weight: 400;

    /* these will make it so the text stays in one line*/
    line-height: 0;
    white-space: nowrap;
}

.step p.active {
    color: var(--scoutNavy);
    font-weight: 700;
}

.divider {
    flex-grow: 1;
    border-bottom: 1px solid var(--pusheenGray);
}

@media tablet {
    .root {
        padding-bottom: 40px;
    }

    .step p {
        font-size: 16px;
    }

    .divider {
        margin: 0 8px;
    }
}