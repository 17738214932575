@value tablet from '~breakpoints';

.root {
    background: var(--scoutNavy);
    width: 100%;
}

.content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 44px var(--w1) 40px;
    color: var(--white);
}

.logo {
    width: 128px;
    margin-bottom: 22px;
}

.root a, .root p {
    color: var(--white);
    font-size: 12px;
    line-height: 16px;
}

.root p {
    margin-top: 16px;
}

@media tablet {
    .content {
        padding: 70px var(--w2) 55px;
    }

    .root a, .root p {
        font-size: 14px;
        line-height: 22px;
    }
}