@value b-desktop, b-mobile from '~breakpoints';

.container {
    padding: 80px var(--column3);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.header {
    margin-top: 0px;
    margin-bottom: 32px;
    font-size: 42px;
}

.requiredAlert {
    font-weight: bold;
}

.requiredAlert::before,
.sectionTitle::after,
.inputLabel::after,
.textareaLabel::after,
.dataLabel::after,
.visitDateLabel::after,
.claimedAmountLabel::after,
.reimbursedLabel::after,
.yourTitleLabel::after,
.petSelection::after,
.checkboxLabel::after,
.injuryDateLabel::after,
.radioHeader::after {
    content: '* ';
    color: var(--redAlert);
}

.sectionTitle {
    margin-top: 72px;
}

.petSelection {
    margin: 56px 0 24px;
}

.petButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.petButton {
    width: 49%;
    height: 60px;
    line-height: 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 24px;
}

.petButtonImage {
    height: 30px;
    width: auto;
}

.petInfoContainer {
    display: flex;
    align-items: center;
}

.petInfoLabel {
    font-size: 14px;
    font-weight: bold;
    color: var(--scoutNavyLight);
}

.petInfoTooltip {
    width: 315px;
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
}

.petInfoIcon {
    margin-left: 6px;
    cursor: pointer;
}

.petInfoValue {
    text-align: right;
    margin-left: 16px;
    font-size: 14px;
    color: var(--scoutNavyLight);
}

.sectionContainer {
    padding: 32px 0;
}

.groupContainer,
.labelContainer,
.auditContainer,
.typeContainer {
    display: flex;
}

.typeContainer {
    margin-bottom: 56px;
}

.auditContainer {
    flex-wrap: wrap;
}

.toggleButton:not(:first-child) {
    margin-left: 8px;
}

.typeToggleButton:not(:first-child) {
    margin-left: 18px;
}

.toggleButton,
.typeToggleButton {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.toggleButtonImage {
    width: 35px;
}

.toggleButtonSubtext {
    margin-top: 32px;
    font-size: 14px;
    color: var(--scoutNavyLight);
}

.radioGroup {
    margin: 16px 0 32px;
}

.inputContainer,
.auditInputContainer {
    width: 100%;
}

.inputContainer:not(:first-child),
auditInputContainer:not(:first-child) {
    margin-left: 20px;
}

.inputLabel,
.visitDateLabel,
.claimedAmountLabel {
    margin-top: 56px;
    margin-bottom: 24px;
}

.textareaLabel {
    margin: 56px 0 16px;
}

.textareaHints {
    margin-bottom: 32px;
}

.container .injuryDateLabel {
    margin-bottom: 24px;
}

.dataLabel,
.reimbursedLabel,
.yourTitleLabel {
    margin-top: 10px;
    margin-bottom: 24px;
}

.dataLabelNotes {
    margin-top: 10px;
    margin-left: 8px;
    font-size: 16px;
    color: var(--scoutNavyLight);
}

.dateVisitContainer {
    padding-top: 8px;
}

.injuryDateContainer {
    width: 100%;
    margin-bottom: 24px;
}

.dollarAdornment {
    width: 42px;
    height: 60px;
    padding: 16px;
    color: var(--white);
    font-size: 20px;
    font-weight: bold;
    background-color: var(--bluesClues);
    border-radius: 8px 0 0 8px;
}

.alignCenter {
    text-align: center;
}

.submit {
    line-height: 20px;
    min-height: 48px;
    width: 240px;
    margin: 34px auto 0;
}

.focusedSearchContainer {
    z-index: 1000;
}

.searchPatient {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.searchPatient:hover {
    color: var(--bluesClues);
}

.searchInvite {
    background: var(--greyLight);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    flex-wrap: wrap;
}

.searchInvite button {
    background: none;
    padding: 0;
    color: var(--royal);
    cursor: pointer;
    border: 0;
    margin-left: 4px;
}

.selectedPatient {
    margin-top: 32px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 26px 32px 14px;
    position: relative;
}

.selectedPatientItem {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 0 12px;
}

.selectedPatientChange {
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--bluesClues);
}

.patientInfoIcon {
    margin-right: 10px;
}

.requestError {
    color: var(--redAlert);
    text-align: center;
    margin: 72px auto 32px;
}

.checkboxLabel {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.checkboxContainer {
    margin-top: 80px;
}

.checkbox {
    width: 18px;
    height: 18px;
}

.fraudNoticeButton {
    min-height: 0;
    padding: 0;
    color: var(--bluesClues);
}

@media b-mobile {
    .container {
        padding: 40px var(--column1);
    }

    .header {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .sectionTitle {
        margin-top: 64px;
    }

    .groupContainer,
    .auditContainer,
    .typeContainer {
        flex-direction: column;
    }

    .toggleButton,
    .typeToggleButton {
        width: auto;
    }

    .toggleButtonSubtext {
        margin-top: 24px;
    }

    .typeToggleButton {
        font-size: 14px;
    }

    .toggleButton:not(:first-child),
    .typeToggleButton:not(:first-child) {
        margin-left: 0;
        margin-top: 24px;
    }

    .selectedPatientChange {
        position: relative;
        top: 0;
        right: 0;
        min-height: 0;
        padding: 0 0 12px;
        text-align: left;
    }

    .petButton {
        width: 100%;
    }

    .inputContainer:not(:first-child),
    .auditInputContainer:not(:first-child) {
        margin-left: 0;
    }

    .inputLabel {
        margin-top: 48px;
    }

    .visitDateLabel {
        margin: 32px 0;
    }

    .claimedAmountLabel,
    .dataLabel,
    .dataLabelNotes,
    .reimbursedLabel,
    .yourTitleLabel {
        margin-top: 2px;
    }

    .dataLabelNotes {
        font-size: 14px;
    }

    .checkboxContainer {
        margin-top: 56px;
    }

    .submit {
        margin-top: 10px;
    }
}

@media b-desktop {
    .searchContainer {
        position: initial;
        width: 100%;
    }

    .searchInvite {
        border-radius: 0 0 8px 8px;
    }

    .radioGroup {
        margin-bottom: 40px;
    }

    .textareaLabel {
        margin-bottom: 8px;
    }

    .textareaHints {
        margin-bottom: 28px;
    }

    .injuryDateContainer {
        width: 49%;
    }

    .auditContainer {
        align-items: flex-end;
        justify-content: space-between;
    }

    .auditInputContainer {
        width: 48%;
    }
}