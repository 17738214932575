@value tablet from '~breakpoints';

.root {
    width: var(--w14);
    background-color: var(--bluesCluesBlue);
    height: initial;
    position: relative;
    overflow: initial;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
}

.backdrop {
    backdrop-filter: blur(8px);
}

.content {
    padding: 32px var(--w1);
}

.content h3 {
    color: var(--zeroWhite);
    text-align: center;
    width: 304px;
    margin: 32px auto 0;
    font-size: 24px;
    line-height: 32px;
}

.firstQuestion, .secondQuestion {
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: var(--zeroWhite);
}

.firstQuestion {
    margin: 30px 0 6px;
}

.secondQuestion {
    margin: 12px 0 6px;
}

.focusedSearchContainer {
    z-index: 1000;
    /* to offset the modals existing var(--w1) */
    margin: 0 calc(-1 * var(--w2));
}

.searchText {
    padding-top: 0;
}

.option {
    border-bottom: none;
    padding: 10px 20px;
    cursor: pointer;
}

.option p:first-child {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--scoutNavy);
}

.option p:last-child {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: var(--gromitGray);
}

.submit {
    width: 100%;
    margin: 32px auto 30px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    display: block;
}

.submit:hover {
    background-color: var(--plutoGold);
    color: var(--scoutNavy);
    border: 1px solid var(--plutoGold);
}

.icon {
    position: absolute;
    background-color: var(--zeroWhite);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
}

.icon img {
    width: 41px;
}

.fieldContainer {
    margin-bottom: 0;
}

@media tablet {
    .root {
        width: 480px;
        min-width: initial;
        min-height: initial;
    }

    .content {
        padding: 24px;
    }

    .content h3 {
        width: 400px;
        margin-top: 52px;
        font-size: 30px;
        line-height: 38px;
    }

    .firstQuestion {
        margin-top: 24px;
    }

    .focusedSearchContainer {
        margin: 0;
    }

    .submit {
        width: 256px;
        margin-bottom: 21px;
        margin-top: 32px;
    }

    .icon {
        width: 96px;
        height: 96px;
    }

    .icon img {
        width: 49px;
    }
}