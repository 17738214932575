@value b-desktop, b-mobile from '~breakpoints';

.modalContent {
    background-color: var(--white);
}

.subheadText {
    font-size: 24px;
    padding-bottom: 24px;
}

.legalText {
    padding-bottom: 24px;
}

@media b-mobile {
    .legalText {
        padding-bottom: 24px;
    }

    .subheadText {
        width: 100%;
        font-size: 24px;
        padding-bottom: 24px;
    }
}

@media b-desktop {
    .modalContainer {
        height: 90vh;
        width: 65vw;
    }

    .modalContent {
        padding: 32px 64px;
    }
}