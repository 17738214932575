@value b-desktop from '~breakpoints';

.award {
    background-color: var(--bluesClues);
}

.seal {
    margin: 0 auto -40px;
    position: relative;
    height: 80px;
    top: -40px;
}

.container {
    padding: 0 var(--column1) 55px;
    max-width: 1440px;
    margin: 0 auto;
}

.logo {
    margin: 20px auto;
    height: 13px;
}

.h3 {
    color: var(--white);
    text-align: center;
}

.h3 .link {
    color: var(--white);
    font-size: 18px;
    vertical-align: super;
}

@media b-desktop {
    .seal {
        height: max-content;
        top: -55px;
        margin-bottom: -55px;
    }

    .container {
        padding: 0 var(--column2) 80px;
    }

    .logo {
        margin: 25px auto;
        height: max-content;
    }
}