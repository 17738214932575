@value b-desktop, b-desktop-only from '~breakpoints';

.wrapper {
    background-color: var(--cream);
}

.container {
    padding: 64px var(--column1);
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.processing {
    margin: 0 auto;
}

.title {
    text-align: center;
}

.subtitle {
    font-size: 16px;
    font-weight: 800;
    margin: 16px 0 48px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.multiBox {
    width: initial;
    height: initial;
    max-height: initial;
    margin-bottom: 32px;
}

.copy {
    max-width: 538px;
    margin-bottom: 16px;
}

.body {
    margin: 24px 0;
}

.copy ul li {
    margin-top: 8px;
    background: url('images/icons/check-blue.svg') no-repeat;
    padding-left: 32px;
    list-style: none;
    font-size: 14px;
    color: var(--scoutNavy);
}

.exclusion {
    font-size: 10px;
    line-height: 12px;
    color: var(--scoutNavy);
    margin-top: 12px;
}

.cards {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.targetTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 32px;
}

.targetTitle img {
    width: 50px;
    margin-right: 16px;
}

.seeMore {
    margin-top: 40px;
    text-align: center;
}

.seeMore a {
    color: var(--royal);
}


@media b-desktop {
    .container {
        padding-top: 96px;
    }

    .subtitle {
        font-size: 18px;
    }

    .copy ul li {
        font-size: 16px;
    }

    .targetTitle {
        margin-top: 96px;
    }

    .seeMore {
        margin-top: 48px;
    }
}

@media b-desktop-only {
    .subtitle {
        margin-bottom: 96px;
    }

    .content {
        flex-direction: row;
        align-items: flex-start;
    }

    .multiBox {
        margin-right: 32px;
        max-width: 50%;
    }

    .seeMore {
        margin-top: 40px;
    }
}