@value b-desktop, b-mobile from '~breakpoints';

.container {
    width: 100%;
    min-height: calc(100vh - 72px);
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sortContainer {
    margin: 25px 0;
}

.resultsContainer {
    width: 100%;
    background: var(--bluesCluesLow);
    flex: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noResults {
    width: var(--column10);
    margin: 0 auto;
    text-align: center;
}

.addPatient {
    margin-top: 32px;
    width: var(--column8);
}

.image {
    margin: 0 auto 32px;
}

.searchContainer {
    /* on top of nav on mobile */
    z-index: 1000;
}

.text {
    display: flex;
}

.stickyFilterContainer {
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: content-box;
    background: var(--white);
    z-index: 1000;
}

.textFieldContainer {
    margin-bottom: 0px;
}

@media b-desktop {
    .noResults {
        width: var(--column4);
    }

    .addPatient {
        width: 200px;
    }

    .searchContainer {
        /* hiding behind pop out menu on desktop */
        z-index: 999;
    }

    .stickyFilterContainer {
        top: 72px;
        z-index: 999;
    }
}

@media b-mobile {

    /* on top of nav on mobile but behind popout menu */
    .searchContainer.nav {
        z-index: 900;
    }
}