@value b-mobile, b-desktop from '../breakpoints.css';

.container {
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: content-box;
    display: flex;
}

.text {
    width: var(--w14);
    margin: 0 auto;
    height: 75px;
    padding-top: 25px;
    box-sizing: content-box;
    display: flex;
}

.cancel {
    color: var(--royal);
    padding-right: 0;
    margin-top: -20px;
}

.activeSelect img {
    transition: 0.1s;
    transform: rotate(180deg);
}

.children {
    width: 100vw;
    /* 78px is for 64px .searchFieldContainer, 2px hr, and 12 px margin btwn the two */
    height: calc(100vh - 78px);
    background: white;
    overflow-y: scroll;
}

.sticky {
    background: var(--greyLight);
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder {
    visibility: hidden;
}

hr {
    display: none;
}

.child {
    border-bottom: 2px solid var(--greyLight);
    cursor: pointer;
}

.active {
    background-color: var(--peteyBlue);
}

.childrenInput {
    height: initial;
}

@media b-desktop {
    .container {
        position: relative;
        top: unset;
    }

    .text {
        width: var(--w6);
    }

    .childrenContainer {
        width: 100%;
    }

    .focusedContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .childrenInput {
        width: 100%;
    }

    .children {
        height: auto;
        max-height: 270px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 100%;
        position: absolute;
        top: calc(100% - 22px);
        visibility: hidden;
    }

    .children:not(:empty) {
        border: 1px solid var(--pusheenGray);
    }

    .desktopShow {
        visibility: visible;
    }

    .placeholder {
        display: none;
    }

    .activeSelect img {
        /* https://codepen.io/sosuke/pen/Pjoqqp to invert from black to bluesCluesBlues */
        filter: invert(33%) sepia(57%) saturate(1857%) hue-rotate(215deg) brightness(102%) contrast(92%);
    }
}

@media b-mobile {
    .focusedContainer {
        transition: 0.3s;
        position: fixed;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100vh;
        width: 100vw;
        margin: 0 calc(-1 * var(--w1));
        box-sizing: border-box;
    }

    .focusedContainer .text {
        flex-direction: column;
        background: var(--zeroWhite);
        width: 100%;
        padding: 0;
    }

    .focusedContainer .container {
        width: var(--w14);
        margin: 0 auto;
    }

    .childrenContainer {
        width: 100%;
    }

    .hiddenCancel {
        display: none;
    }

    .sticky {
        position: absolute;
    }

    .focusedContainer hr {
        width: 100%;
        display: block;
        margin: 12px 0 0;
        border-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }

    .focusedContainer .inputContainer {
        border: none;
    }
}