.uploadDiv {
  width: 100%;
  border: 2px solid var(--shilohGray);
  border-radius: 8px;
  display: block;
  justify-content: center;
  box-sizing: border-box;
}

.uploadDivError {
  width: 100%;
  border: 1px solid var(--crookshanksRed);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.uploadDivWithFiles {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.processing {
  padding: 110px 0;
}

.uploadButtonContainer {
  margin-top: 4px;
}

div.browseFileText {
  color: var(--dinoBlue);
}

.buttonUploadCircle {
  background: var(--bluesCluesBlue);
  border-radius: 50%;
  border: 7px solid var(--zeroWhite);
  height: 45px;
  width: 45px;
  box-sizing: content-box;
  margin: 42px auto 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buttonUploadCircle:before {
  content: ' ';
  position: absolute;

  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  border: 1px solid var(--bluesCluesBlue);
  border-radius: 50%;
}

.labelUpload {
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.uploadFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.uploadInfoDiv {
  max-width: 300px;
  margin: 24px auto 42px;
}

.uploadInfoDiv .acceptedFormatsDiv,
.uploadInfoDiv .maxFileSizeDiv {
  color: var(--gromitGray);
}

.uploadInfoDiv .uploadErrorText {
  color: var(--crookshanksRed);
  font-weight: bold;
  margin-top: 16px;
}

.uploadedFile {
  border-top: 1px solid var(--shilohGray);
  display: flex;
  padding: 18px 24px;
}

.uploadedFileIcon {
  position: relative;
}

.checkIcon {
  position: absolute;
  bottom: -4px;
  right: -3px;
}

.uploadedFileLabel {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
}

.uploadedFileLabel .uploadedFileName {
  font-weight: bold;
  color: var(--bluesCluesBlue);
}

.baseButton {
  outline: none;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 16px;
  width: auto;
  min-height: 48px;
  background-color: var(--zeroWhite);
  transition-property: background-color, box-shadow, border, color;
}

.uploadedFileDelete {
  color: var(--bluesCluesBlue);
  border: none;
  font-weight: normal;
  min-height: 0;
  padding: 0;
}