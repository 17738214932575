@value tablet from '~breakpoints';

.root {
    padding: 0 var(--w1);
    color: var(--scoutNavy);
    text-align: center;
    max-width: 1440px;
    margin: -38px auto 83px;
}

.root h2 {
    margin: 0 auto 20px;
    font-size: 30px;
    line-height: 38px;
}

.subtitle {
    margin: 0 auto 24px;
    font-size: 16px;
    line-height: 24px;
}

.customerService {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 29px;
}

.customerService img {
    width: 90px;
}

.contactItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 18px 0;
    margin: 28px 0 40px;
}

.contactItem {
    display: flex;
    align-items: center;
}

.contactItem img {
    margin-right: 17px;
    height: 64px;
}

.contactItemText {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.contactItem p {
    font-size: 16px;
    line-height: 24px;
    color: var(--scoutNavy);
}

.linkText {
    font-weight: 800;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--bluesClues);
    text-decoration: none;
}

@media tablet {
    .subtitle {
        margin-bottom: 38px;
        font-size: 18px;
        line-height: 28px;
    }

    .root {
        margin-top: -44px;
    }

    .root h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .customerService img {
        width: 130px;
    }
}