.alert {
    width: 100%;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.success {
    border-color: var(--primaryGreen);
}

.image {
    margin-right: 16px;
}

.success .image {
    background-image: url('green-check.svg');
}