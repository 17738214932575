.container {
    padding: 24px;
    position: absolute;
    background: white;
    z-index: 998;
    box-sizing: border-box;
}

.backdrop {
    position: absolute;
    top: 72px;
    left: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}