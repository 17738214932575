@value b-desktop, b-desktop-only from '~breakpoints';

.title {
    text-align: center;
    margin-top: 6px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.imageContainer {
    position: relative;
    width: 100%;
    min-width: 311px;
    margin: 0 auto;
}

.imageAction {
    margin: 0 auto;
}

.frame {
    height: calc((100vw - 64px) * (389 / 311));
    width: 100%;
    max-height: 389px;
    max-width: 311px;
}

.userPhoto {
    width: calc(100% - 40px);
    margin: 20px 0 0 20px;
    background-size: cover;
    background-position: 50%;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
}

/* forces userPhoto to be a square without setting height */
.userPhoto:after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.retake {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 12px auto;

    color: var(--royal);
    font-weight: 600;
    font-size: 14px;
}

.icon {
    margin-right: 10px;
    width: 18px;
}

.carouselContainer {
    max-width: 100vw;
    overflow-x: scroll;
    margin: 0 auto;
}

.carousel {
    display: inline-flex;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    margin: auto;
}

.option {
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    min-width: 94px;
    margin: 0 7px;
    padding: 14px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.optionIcon {
    height: 24px;
    width: auto;
    min-width: 20px;
    margin-bottom: 10px;
}

.optionText {
    opacity: 1;
}

.optionText.disabledText {
    color: var(--scoutNavyHalf);
}

.submit {
    margin: 16px auto;
    width: 260px;
    display: block;
}

@media b-desktop {
    .content {
        flex-direction: row;
        margin-top: 40px;
        justify-content: space-around;
    }

    .imageAction {
        margin: 0;
    }

    .carouselContainer {
        display: flex;
        width: 375px;
        justify-content: center;
        overflow-x: unset;
        padding: 0 15px;
    }

    .carousel {
        flex-wrap: wrap;
        width: 100%;
        padding-right: 0;
    }

    .option {
        margin-bottom: 16px;
    }

    .submit {
        margin-top: 48px;
    }
}

@media b-desktop-only {
    .optionIcon {
        height: 30px;
    }
}

@media screen and (min-width: 330px) and (max-width: 400px) {
    .option {
        margin: 0 14px;
    }
}