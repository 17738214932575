@value b-desktop from '~breakpoints';

.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    min-height: calc(100vh - 352px);
    /* 72px header, 280px footer */
}

.desktop {
    display: none;
}

.content {
    width: 100%;
    padding: 0 var(--column1);
}

.activePetInfo {
    padding: 20px 0;
    border-top: 1px solid var(--grey);

    display: flex;
    align-items: flex-start;
}

.activePetImage {
    margin-right: 16px;
    height: 64px;
    width: 64px;
}

.activePetText {
    flex: 1;
}

.activePetItem {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
}

.activePetItem img {
    height: 16px;
    width: 16px;
    margin-right: 8px;
}

.module {
    border-top: 1px solid var(--grey);
    width: 100%;
    padding: 20px 0;
}

.moduleHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
}

.moduleIcon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.moduleLineItem {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.moduleCenterText {
    width: 340px;
    margin: 0 auto;
    text-align: center;
}

@media b-desktop {
    .container {
        flex-direction: row;
    }

    .desktop {
        display: block;
    }

    .content {
        padding: 40px;
    }

    .activePetInfo {
        align-items: center;
        margin-top: 20px;
    }

    .activePetText {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .activePetText h4 {
        flex: 100%;
    }

    .activePetItem {
        align-items: center;
        margin: 12px 20px 0 0;
    }

    .moduleHeader {
        flex-wrap: nowrap;
    }
}