.root {
    display: flex;
}

.answer, .base {
    height: 800px;
    width: 800px;
    display: flex;
    flex-direction: column;
}

.answer {
    background-color: var(--zeroWhite);
    padding-left: 80px;
    justify-content: center;
    box-shadow: 0 0 0 100vmax var(--zeroWhite);
}

.base {
    background-color: var(--bluesCluesBlue);
    align-items: center;
    text-align: center;
    padding: 56px 0;
    box-shadow: 0 0 0 100vmax var(--bluesCluesBlue);
}

.pill {
    background-color: var(--zeroWhite);
    color: var(--bluesCluesBlue);
    font-weight: 800;
    padding: 4px 16px;
    display: inline-block;
    border-radius: 16px;
}

.base h1 {
    color: var(--zeroWhite);
    margin: 28px auto 32px;
    max-width: 688px;
}

.options {
    width: 640px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 32px;
    flex: 1;
}

.option {
    border: 3px solid var(--zeroWhite);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--zeroWhite);
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
}

.correct {
    border-color: var(--astroGreen);
    background-color: var(--astroGreen);
}

.answer h1 {
    width: 560px;
    color: var(--scoutNavy);
}

.citation {
    margin-top: 24px;
    width: 496px;
}