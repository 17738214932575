.button {
    background: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 0 15px;
    min-height: 48px;
    transition-property: background-color, box-shadow, border, color;
}

.primary {
    background-color: var(--plutoGold);
    color: var(--scoutNavy);
    border: 1px solid var(--plutoGold);
}

.primary:hover {
    background-color: var(--bluesCluesBlue);
    border: 1px solid var(--bluesCluesBlue);
    color: white;
}

.secondary {
    background-color: var(--white);
    border: 1px solid var(--bluesCluesBlue);
    color: var(--bluesCluesBlue);
}

.secondary:hover {
    background-color: var(--bluesCluesBlue);
    color: var(--white);
}

.primary:disabled,
.primary.disabled {
    background-color: var(--porkchopGray);
    color: var(--shilohGray);
    border: 1px solid var(--softGrey);
    cursor: not-allowed;
}

.secondary:disabled,
.secondary.disabled {
    border-color: var(--scoutNavy);
    background: var(--white);
    color: var(--scoutNavy);
    opacity: 0.5;
    cursor: not-allowed;
}