.toggleButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: 16px;

  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 14px;
  color: var(--scoutNavy);

  border-color: var(--grey);
  background: var(--white);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-sizing: content-box;
  text-align: left;
}

.img {
  width: auto;
}

.toggleButton:hover {
  border-color: var(--bluesClues);
}

.toggleButton:focus {
  border-color: var(--bluesClues);
  outline: none;
}

.toggleButtonSelected {
  color: var(--white);
  background-color: var(--bluesClues);
  border-color: var(--bluesClues);
}

.toggleButtonVertical {
  flex-direction: column;
  justify-content: center;
}

.imageVertical {
  margin-bottom: 8px;
}

.imageHorizontal {
  margin-right: 8px;
}

.toggleButton span {
  align-self: center;
}

.toggleButtonVertical span {
  text-align: center;
}

.portal.toggleButtonSelected {
  font-weight: 800;
  background-color: var(--peteyBlue);
  border: 2px solid var(--bluesCluesBlue);
  color: var(--scoutNavy);
}