@value tablet, desktop from '~breakpoints';

.root {
    display: flex;
    flex-direction: column;
    padding: 0 var(--w1);
    text-align: center;
    color: var(--scoutNavy);
}

.left {
    width: 100%;
}

.left h2 {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 38px;
}

.traits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 18px 0;
    margin: 18px 0 19px;
}

.trait {
    flex: 40%;
}

.trait img {
    margin: 0 auto 8px;
    height: 40px;
}

.trait p {
    font-size: 18px;
    line-height: 28px;
    color: var(--scoutNavy);
}

.careTips {
    margin-bottom: 8px;
    padding-top: 21px;
}

.careTipsHeader, .trainingTipsHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 18px;
    gap: 10px 0;
}

.careTips h3, .trainingTips h3 {
    flex: 100%;
    font-size: 24px;
    line-height: 32px;
}

.from {
    display: flex;
    align-items: center;
    gap: 0 5px;
    padding-bottom: 2px;
}

.from img {
    width: 20px;
}

.tip {
    display: flex;
}

.tip h4 {
    display: none;
}

.tipTitle {
    margin-bottom: 16px;
}

.tipDescription {
    margin-bottom: 32px;
}

.right {
    width: 100%;
    margin-top: 98px;
}

.aside {
    background-color: var(--peteyBlue);
    width: 100%;
    position: relative;
}

.info {
    padding: 38px var(--w1) 32px;
}

.info p, .info li {
    font-size: 14px;
    line-height: 22px;
    color: var(--scoutNavy);
}

.info p, .info ul {
    margin-bottom: 18px;
}

.info ul {
    padding: 0 var(--w1);
    display: flex;
    flex-direction: column;
    gap: 18px 0;
}

.button {
    width: 100%;
}

.ribbon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--bluesCluesBlue);
    color: var(--white);

    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    z-index: 1;
    text-align: center;
    min-width: 235px;
    padding: 8px 0 4px;
}

.aside img {
    position: absolute;
    top: -44px;
    left: 50%;
    width: 62px;
    transform: rotate(-8deg) translate(-50%, -50%);
    z-index: 2;
}

@media tablet {
    .root {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    .left {
        width: var(--w8);
    }

    .left h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .traits {
        flex-wrap: nowrap;
        margin: 32px 0 19px;
    }

    .trait {
        flex: 1;
    }

    .trait img {
        margin: 0 0 14px;
    }

    .careTips {
        margin-bottom: 32px;
        padding-top: 53px;
    }

    .careTipsHeader, .trainingTipsHeader {
        display: inline-flex;
        justify-content: flex-start;
        align-items: flex-end;
        position: relative;
    }

    .careTips h3, .trainingTips h3 {
        flex: initial;
        margin-right: 12px;
        font-size: 30px;
        line-height: 38px;
    }

    .tip span {
        width: 38px;
    }

    .tip h4 {
        color: var(--zeroWhite);
        background: var(--bluesCluesBlue);
        margin-right: 22px;

        display: flex;
        flex: 0 0 38px;
        height: 36px;
        justify-content: center;
        align-items: center;
        transform: rotate(-2deg);
    }

    .tipTitle {
        margin-bottom: 24px;
    }

    .right {
        width: var(--w3);
        margin-top: 0;
    }

    .ribbon {
        padding: 4px 8px 4px 16px;
        width: 90%;
        min-width: auto;
        text-align: left;
    }

    .info {
        padding: 38px 24px 32px;
    }

    .info ul {
        gap: 0;
        padding: 0 0 0 16px;
    }

    .aside img {
        visibility: hidden;
    }
}

@media desktop {
    .aside img {
        visibility: visible;
        left: 0;
        top: 0;
    }
}