@value tablet from '~breakpoints';

.root {
    padding: 40px var(--w1) 48px;
    color: var(--scoutNavy);
    text-align: center;
    background: var(--benjiBlue);
}

.wave {
    width: 100%;
    height: 5px;
}

.root h2 {
    margin: 0 auto 44px;
    font-size: 30px;
    line-height: 38px;
}

.resources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.resource {
    width: 160px;
    height: 200px;
    background: var(--white);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.resource:hover {
    text-decoration: underline;
}

.resource img {
    width: 160px;
    height: 84px;
    border-radius: 8px 8px 0 0;
    margin-bottom: 11px;
}

.title {
    text-align: left;
    padding: 0 16px 16px 16px;
}

.title p {
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    color: var(--scoutNavy);
}

@media tablet {
    .wave {
        height: 20px;
    }

    .root {
        padding: 80px var(--w1) 128px;
    }

    .root h2 {
        margin: 0 auto 61px;
        font-size: 36px;
        line-height: 44px;
    }

    .resource {
        width: 254px;
        height: 240px;
    }

    .resource img {
        width: 100%;
        height: 136px;
        margin-bottom: 16px;
    }

    .title p {
        font-size: 16px;
        line-height: 24px;
    }

    .title {
        padding: 0 24px 16px;
    }
}