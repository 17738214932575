.container {
    width: var(--column10);
    max-width: 356px;
    margin: 40px auto 0;
    min-height: 70vh;
    padding-bottom: 120px;
}

.text,
.title {
    margin-bottom: 24px;
    text-align: center;
}

.title {
    display: flex;
    align-items: center;
}

.success {
    margin-right: 10px;
}

.button {
    display: block;
    width: 100%;
}

.reset {
    color: var(--bluesClues);
    cursor: pointer;
}

.error {
    color: var(--redAlert);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
}