@value b-desktop from '~breakpoints';

.photoContainer {
    margin-top: 20px;
    padding: 30px;
    border: 1px solid var(--grey);
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    background: var(--white);
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.framesError {
    color: var(--redAlert);
    margin-top: 10px;
}

.iconContainer {
    background: var(--bluesClues);
    border-radius: 50%;
    border: 7px solid var(--white);
    height: 45px;
    width: 45px;
    box-sizing: content-box;
    margin: 0 auto 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.disabledInput {
    background: var(--grey);
}

.iconContainer:before {
    content: ' ';
    position: absolute;

    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    border: 1px solid var(--bluesClues);
    border-radius: 50%;
}

.disabledInput:before {
    border-color: var(--grey);
}

.photoBody {
    color: var(--bluesClues);
    margin-bottom: 8px;
    display: block;
}

.hidden {
    display: none;
}

.petPicTipsButton {
    display: flex;
    align-items: center;
    margin: 24px auto 0;
    border: 1px solid var(--grey);
    border-radius: 10px;
    font-weight: normal;
}

.lightbulbIcon {
    width: 24px;
    margin-right: 8px;
}

@media b-desktop {
    .photoContainer {
        padding: 45px;
    }

    .iconContainer,
    .photoBody {
        margin-bottom: 24px;
    }
}