@value b-desktop, b-desktop-only from '~breakpoints';

.title {
    text-align: center;
    margin-top: 160px;
}

.loader {
    width: 100%;
}

.backButton {
    position: absolute;
    top: 23px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--royal);
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;

    min-height: 0;
    padding: 0;
    font-weight: 600;
}

.chevron {
    margin-right: 8px;
    transform: rotate(180deg);
    height: 12px;
}

.modal {
    margin: auto auto;
    width: var(--column10);
    max-width: 313px;
    height: inherit;
    text-align: center;
    border-radius: 8px;
    min-width: 0;
    min-height: 0;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.modalButton {
    flex: 1;
}

@media b-desktop {
    .container {
        width: var(--column10);
        min-height: calc(100vh - 235px);
        box-sizing: content-box;
    }

    .backButton {
        top: 43px;
        left: 40px;
    }

    .modal {
        min-width: 380px;
    }
}

@media b-desktop-only {
    .container {
        width: var(--column8);
    }
}