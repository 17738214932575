@value tablet from '~breakpoints';

.wave {
    width: 100%;
    height: 18px;
    position: relative;
    background-image: url('images/illustrations/shapes/wave-top-royal-mobile.svg');
    background-size: cover;
    background-repeat: no-repeat;
    resize: none;
    background-position: center;
}

.wave img {
    position: absolute;
    left: 48px;
    top: 5px;
    transform: translateY(-50%);
}

.footer {
    margin-top: -1px;
    background: var(--snoopyBlue);
    width: 100%;
    padding: 32px var(--w2);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer p, .footer a {
    color: var(--zeroWhite);
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

@media tablet {
    .wave {
        height: 20px;
        background-image: url('images/illustrations/shapes/wave-top-royal-tablet.svg');
    }

    .wave img {
        left: 86px;
    }

    .footer {
        padding: 32px var(--w1);
    }
}