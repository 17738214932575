.container {
  overflow-x: hidden;
  position: relative;
}

.itemsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: 0.4s ease-in;
  height: 100%;
  position: absolute;
  top: 0;
}

.item,
.container {
  height: 100%;
  width: 100%;
}

.indicators {
  padding-inline-start: 0px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
}

.indicator {
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--grey);
}

.indicatorActive {
  background: var(--bluesClues);
}

.arrowLeft,
.arrowRight {
  cursor: pointer;
  border: none;
  width: 30px;
  padding: 0;
  background: transparent;
  position: absolute;
  z-index: 50;
  top: 50%;
}

.arrowLeft {
  left: 0;
  transform: scaleX(-1);
}

.arrowRight {
  right: 0;
}

.arrowLeftImage,
.arrowRightImage {
  width: 15px;
  margin: 0 auto;
  filter: grayscale(100%);
}

.arrowLeftDisabled,
.arrowRightDisabled {
  cursor: inherit;
}

.arrowLeftDisabled .arrowLeftImage,
.arrowRightDisabled .arrowRightImage {
  filter: grayscale(100%) opacity(25%);
}