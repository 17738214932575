@value tablet from '~breakpoints';

.root {
    padding: 0 var(--w1);
    color: var(--scoutNavy);
    text-align: center;
}

.root h2 {
    margin: 0 auto 25px;
    max-width: 900px;
    font-size: 30px;
    line-height: 38px;
}

.root h3 {
    margin: 0 auto 22px;
    max-width: 614px;
    font-size: 24px;
    line-height: 32px;
}

.subheader {
    margin: 0 auto 30px;
    font-size: 16px;
    line-height: 24px;
    max-width: 810px;
}

.about {
    display: flex;
    flex-direction: row;
    max-width: 720px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 18px 0;
    margin: 0 auto 55px;
}

.aboutItem {
    width: 160px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.about img{
    height: 40px;
    margin-bottom: 9px;
}

.itemTitle {
    line-height: 24px;
    font-size: 16px;
    font-weight: 800;
}

.itemSubtitle {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
}

.mobile {
    width: 100%;
}

.desktop {
    display: none;
}

.conditions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px 32px;
    justify-content: center;
}

.condition {
    display: flex;
    gap: 0 24px;
}

.accordionContainer {
    width: 100%;
}

.accordionHeader {
    background: var(--bluesCluesBlue);
    padding: 11px 23px 9px 16px;
    color: var(--white);
    min-height: 48px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 800;
}

.accordionContent {
    position: relative;
    top: initial;
    box-shadow: none;
    border-left: 1px solid var(--porkchopGray);
    border-right: 1px solid var(--porkchopGray);
    padding: 1px 0 1px 0;
}

.accordionContent>* {
    border-bottom: none;
}

.accordionHeader img {
    filter: invert(100%) sepia(0%) saturate(4894%) hue-rotate(182deg) brightness(126%) contrast(109%);
}

.accordionRow {
    margin: 12px 20px 14px 24px;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
}

.accordionRow p, .conditionBody p {
    margin-bottom: 8px;
}

.accordionRow a, .prices a, .conditionBody a {
    cursor: pointer;
    color: var(--bluesClues);
    text-decoration: none;
}

.prices a {
    display: flex;
    align-items: center;
    gap: 8px;
}

.prices {
    background: var(--cream);
    text-align: left;
    padding: 8px 8px 12px 24px;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
}

.prices img {
    height: 14px;
    margin-top: -2px;
}

.conditionContent {
    max-width: 524px;
}

.note {
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: var(--lightGrey);
    max-width: 680px;
    margin: 16px auto 0;
}

.conditionHeader {
    background: var(--bluesCluesBlue);
    padding: 0 23px;
    color: var(--white);
    height: 48px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: left;
}

.conditionBody {
    padding: 12px 24px 14px 24px;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    border-left: 1px solid var(--porkchopGray);
    border-right: 1px solid var(--porkchopGray);
    height: 180px;
}

@media tablet {
    .root h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .root h3 {
        font-size: 30px;
        line-height: 38px;
    }

    .subheader {
        margin-bottom: 38px;
        font-size: 18px;
        line-height: 28px;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: block;
    }

    .about img{
        margin-bottom: 11px;
    }

    .itemTitle {
        line-height: 28px;
        font-size: 18px;
    }

    .itemSubtitle {
        line-height: 28px;
        font-size: 18px;
    }

    .aboutItem {
        width: 180px;
    }
}