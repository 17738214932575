.root {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    background-color: var(--bluesCluesBlue);
    height: 800px;
    position: relative;
    width: 480px;
    box-shadow: 100vmax 100vmax 0 100vmax var(--bluesCluesBlue);
}

.logo {
    width: 120px;
    opacity: 0.5;
    margin-top: 24px;
}

.card {
    width: 400px;
    height: 576px;
    margin-top: 90px;
    padding: 16px;

    background-color: var(--zeroWhite);
    border-radius: 16px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dots {
    width: 112px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
}

.dot {
    width: 16px;
    height: 16px;
    background-color: var(--huckleberryBlue);
    border-radius: 50%;
}

.dot.active {
    background-color: var(--zeroWhite);
}