.container {
    position: relative;
    font-size: 12px;
}

.label {
    font-weight: bold;
}

.note {
    margin: 8px 0 12px;
    font-size: 12px;
}

.multiEmailContainer {
    cursor: text;
    background-color: var(--white);
    border: 1px solid var(--grey);
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
}

.tag {
    background-color: var(--bluesClues);
    color: var(--white);
    font-weight: bold;
    border-radius: 8px;
    margin: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
}

.invalid {
    background-color: var(--white);
    color: var(--redAlert);
    border: 1px solid var(--redAlert);
}

.remove {
    background-color: var(--bluesClues);
    border: none;
    color: var(--white);
    padding: 0;
    cursor: pointer;
    margin-left: 10px;
}

.invalid .remove {
    background-color: var(--white);
}

.input {
    max-width: 100%;
    height: 32px;
    margin: 4px;
    border: none;
    background-color: transparent;
    z-index: 1;
}

.input:focus-visible {
    outline-color: var(--orange);
}

.remove img {
    width: 10px;
}

.invalid .remove img {
    filter: brightness(0.5) sepia(1) saturate(10000%);
}


.error {
    color: var(--redAlert);
    border-color: var(--redAlert);
}

.errorMessage {
    color: var(--redAlert);
    font-size: 12px;
    margin-top: 8px;
    min-height: 20px;
}

.hidden {
    display: none;
}