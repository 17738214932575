@value b-desktop from '~breakpoints';

.container, .processing {
    /* 72px nav, 72px terms, 280px footer */
    min-height: calc(100vh - 424px);
    max-width: 1440px;
    padding-bottom: 24px;
    margin: 0 auto;
}

.processing {
    display: flex;
    align-items: center;
    justify-content: center;
}

.module,
.resetForm {
    margin: 40px var(--column1) 24px;
}

.header {
    padding: 40px 0;
    width: 100%;
    text-align: center;
    background: var(--bluesCluesLow);
}

.module {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey);
}

.flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.change {
    display: block;
    margin-left: auto;
    padding: 0;
}

.change div,
.contact {
    color: var(--royal);
}

.center,
.contact {
    text-align: center;
    display: block;
}

.alert {
    margin: 24px auto 32px;
}

@media b-desktop {

    .module,
    .resetForm {
        margin: 40px var(--column3) 24px;
    }
}