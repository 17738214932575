@value desktop from '~breakpoints';

.root {
    background-color: var(--benjiBlue);
    width: 100%;
    margin: 0 auto;
}

.content {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 48px var(--w1);
}

.text {
    max-width: 376px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.text h3 {
    font-size: 24px;
    line-height: 32px;
    max-width: 350px;
    margin: 0 auto 24px;
}

.text p {
    font-size: 16px;
    line-height: 24px;
    color: var(--scoutNavy);
}

.text p:not(:last-of-type) {
    margin-bottom: 16px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: var(--w1);
    margin-top: 32px;
}

.buttons a {
    flex: 1;
}

.buttons a button {
    width: 100%;
}

.root img {
    display: none;
}

@media desktop {
    .content {
        padding: 80px var(--w3);
    }

    .text {
        text-align: left;
        margin: 0 0 0 50%;
    }

    .text h3 {
        margin: 0 0 16px;
    }

    .root img {
        display: block;
        position: absolute;
        bottom: 0;
        left: var(--w3);
        height: 90%;
    }
}