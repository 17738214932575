@value b-desktop, b-desktop-only from '~breakpoints';

.container {
    padding: 64px var(--column1);
    text-align: center;
    max-width: 1440px;
    margin: 0 auto;
}

.steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 64px;
}

.step {
    width: 100%;
    margin-top: 56px;
}

.step img {
    width: max-content;
    height: max-content;
    margin: 0 auto 20px;
}

.button {
    width: 100%;
    max-width: 376px;
}

@media b-desktop {
    .container {
        padding: 96px var(--column1);
    }

    .steps {
        margin-top: 8px;
    }

    .step {
        width: 288px;
        margin-top: 48px;
    }

    .step:last-child {
        margin: 48px auto 0;
    }
}

@media b-desktop-only {
    .step {
        width: var(--column3);
    }

    .step:last-child {
        margin: 48px 0 0;
    }
}