.root p:not(.number) {
    color: var(--scoutNavy);
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -37%);

    width: 130px;
}

.root h4 {
    margin: 110px auto 24px;
    width: 320px;
    font-size: 24px;
    line-height: 32px;
}

.discount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin: 0 auto 20px;
    width: 248px;
}

.number {
    font-weight: 800;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -1px;
    margin-right: 6px;
    font-family: var(--heading-font);

    color: var(--bluesCluesBlue);
}

.discount p {
    max-width: 144px;
}

.contact {
    width: 300px;
    margin: 8px auto 40px;
}

.block {
    width: 100%;
    border-radius: 8px;
    padding: 14px 24px 24px;
    background-color: var(--peteyBlue);
}

.block h5 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    font-family: var(--heading-font);
    font-weight: 800;
}