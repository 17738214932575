.content {
    padding: 40px var(--column1) 30px;
    background: var(--scoutNavy);
    color: var(--white);
}

.content a,
.content div {
    max-width: 1440px;
    margin: 0 auto 12px;
    color: var(--white);
    font-size: 10px;
    line-height: 14px;
}

.content>a {
    display: block;
}

.content a {
    text-decoration: underline;
}

.content a:visited {
    color: var(--white);
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
}

.separator {
    margin: 0 8px;
    color: var(--scoutNavy);
}

.link {
    font-size: 12px;
    line-height: 16px;
    color: var(--scoutNavy);
}

.link:visited {
    color: var(--scoutNavy);
}

.content a:hover,
.link:hover {
    text-decoration: none;
}
