.root h3 {
    margin-bottom: 8px;
}

.title {
    margin-bottom: 20px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin-top: 24px;
}

.submit {
    margin: 0 auto;
    display: block;
}

.error {
    color: var(--crookshanksRed);
    font-size: 14px;
    margin-bottom: 16px;
    text-align: center;
}