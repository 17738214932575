@value b-desktop from '~breakpoints';

.container {
    width: var(--column10);
    max-width: 350px;
    margin: 40px auto 0;
    min-height: 70vh;
    padding-bottom: 120px;
}

.image {
    margin: 0 auto;
}

.header {
    margin: 40px auto 24px;
    text-align: center;
}

.subtext {
    text-align: center;
    opacity: 1;
}

.asterisk {
    color: var(--redAlert);
}

.contact {
    text-align: center;
    margin-top: 18px;
}

.error {
    margin-top: 20px;
    font-size: 14px;
    color: var(--redAlert);
    text-align: center;
}

.form {
    margin-top: 34px;
}

.checkbox {
    margin: 32px 0 28px;
    align-items: flex-start;
}

.box {
    top: 4px;
}

.submit {
    width: 100%;
    margin-bottom: 6px;
}

.errorContainer {
    width: 100%;
    max-width: 313px;
    margin-bottom: 60px;
}

.errorHeader {
    color: var(--puppyPurple);
    margin-bottom: 24px;
}

.errorSubheader {
    margin-bottom: 32px;
}

.errorCopy {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
}

.errorButton {
    width: 100%;
    max-width: 360px;
}

@media b-desktop {
    .errorContainer {
        max-width: 512px;
        margin-bottom: 70px;
    }

    .errorCopy {
        font-size: 24px;
        line-height: 32px;
    }
}