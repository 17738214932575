.green,
.red {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.green span,
.green div {
    color: var(--primaryGreen);
}

.red span,
.red div {
    color: var(--redAlert);
}

.copy {
    font-size: 12px;
    line-height: 16px;
}

.icon {
    width: 16px;
    margin-right: 8px;
}