@value desktop from '~breakpoints';

.marvelContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left {
    background: var(--black);
    background-image: url("marvels-small.png");
    height: 370px;
    background-position: center center;
}

.right {
    width: 100%;
    flex: 1;
    background: var(--bluesCluesBlue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.right h3 {
    color: var(--white);
    text-align: center;
}

.rightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.rightContent > *{
    margin-bottom: 16px;
}

.logo {
    width: 144px;
    margin-bottom: 0;
}

.heroes {
    width: 300px;
}

.subtitle {
    max-width: 540px;
    color: var(--white);
    text-align: center;
}

.checkmarks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.checkmark {
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.checkmark > * {
    color: var(--white);
}

.checkmark > img {
    margin-inline: 4px;
    width: 17px;
}

@media desktop {
    .marvelContainer {
        flex-direction: row;
    }

    .left {
        background-image: url("marvels.png");
        height: 556px;
        background-position: center right;
        background-repeat: no-repeat;
        flex: 1;
    }

    .right {
        justify-content: flex-start;
    }

    .rightContent {
        padding: 0 60px 0 60px;
    }
}