.img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -37%);

    width: 333px;
}

.root h3 {
    margin: 96px auto 40px;
    width: 240px;
    font-size: 32px;
    line-height: 40px;
}

.quotesBlock {
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;
}

.quotesBlock > p {
    background-color: var(--bluesCluesBlue);
    color: var(--zeroWhite);
    width: 100%;
    padding: 12px 8px;
    border-radius: 8px 8px 0 0;

    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    text-align: center;
}

.currentMonth > p {
    background-color: var(--cliffordRed);
}

.data {
    background: var(--peteyBlue);
    width: 100%;
    padding: 24px 16px;
    border-radius: 0 0 8px 8px;
    display: flex;
}

.currentMonth .data {
    background: var(--gidgetPink);
}

.item {
    width: 50%;
    text-align: center;
}

.number {
    font-weight: 800;
    font-size: 80px;
    line-height: 1;
    letter-spacing: -1px;

    color: var(--bluesCluesBlue);
}

.currentMonth .number {
    color: var(--cliffordRed);
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    color: var(--scoutNavy);
}

.block {
    width: 100%;
    border-radius: 8px;
    padding: 16px 16px 24px;
    background-color: var(--peteyBlue);
}

.block h4 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.block p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}