@value b-desktop from '~breakpoints';

.title {
    text-align: center;
    margin-top: 48px;
}

.subtext {
    margin-top: 20px;
    margin-bottom: 48px;
    text-align: center;
    opacity: 1;
}

.asterisk {
    color: var(--redAlert);
}

.field {
    margin-top: 16px;
}

.checkbox {
    margin-top: 44px;
    align-items: flex-start;
}

.submit {
    width: 250px;
    margin: 40px auto;
    display: block;
}

.box {
    top: 4px;
}

@media b-desktop {
    .container {
        width: 350px;
        margin: auto;
    }

    .title {
        margin-top: 24px;
    }
}