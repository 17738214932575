.text {
    margin-bottom: 34px;
    text-align: center;
}

.button {
    display: block;
    width: 100%;
    margin-top: 24px;
}

.error {
    color: var(--redAlert);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
}