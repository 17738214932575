@value b-desktop, b-desktop-only, b-desktop-large from '~breakpoints';

.container {
    padding: 64px var(--column1);
    max-width: 1440px;
    margin: 0 auto;
}

.h2 {
    text-align: center;
    margin-bottom: 48px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.ambulance {
    width: initial;
    height: initial;
    max-height: initial;
    margin-bottom: 32px;
}

.copy {
    max-width: 538px;
    margin-bottom: 16px;
}

.body {
    margin: 24px 0;
}

.copy ul li {
    margin-top: 8px;
    background: url('images/icons/check-blue.svg') no-repeat;
    padding-left: 32px;
    list-style: none;
    font-size: 14px;
    color: var(--scoutNavy);
}

.buttons,
.buttons button {
    width: 100%;
}

.button {
    margin-top: 16px;
    padding: 16px 32px;
}

@media b-desktop {
    .container {
        padding: 96px var(--column1) 80px;
    }

    .copy ul li {
        font-size: 16px;
    }
}

@media b-desktop-only {
    .content {
        flex-direction: row;
        align-items: flex-start;
    }

    .ambulance {
        max-width: 50%;
        margin-right: 32px;
    }

    .h2 {
        margin-bottom: 96px;
    }

    .buttons {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 422px;
    }

    .buttons a {
        flex: 1;
        min-width: 203px;
    }

    .button {
        margin-top: 0;
    }
}